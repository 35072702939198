import React, { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../src/config";
import Footer from "./components/Footer";
import url from "./config/axios";
import { ChatContext } from "./Contexts/ChatContext";
import Header from "./pages/Homepage";
import ScrollToTop from "./pages/ScrollToTop";
import Routes from "./Routes/Routes";
import { useHistory } from "react-router-dom";

import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

let logo = require("../src/images/" + config.favIcon);

export default function App() {
  let history = useHistory();

  const uid = JSON.parse(localStorage.getItem("user"));
  if (uid === null) {
    localStorage.removeItem("user");
  }
  let loggedIn = localStorage.getItem("user") && uid !== null ? true : false;
  const [chatUs, setChatUs] = useState(false);
  const [wishCount, setWishCount] = useState(0);
  const [notificationCount, setNotificationCount] = useState(0);
  const [cartCount, setCartCount] = useState(0);
  const [prodChat, setProdChat] = useState([]);
  const [footerLogo, setFooterLogo] = useState([]);
  const [topHeader, setTopHeader] = useState([]);
  const [LoginModal, setLoginModal] = useState(false);
  const [redirectUser, setRedirectUser] = useState([]);
  const [redirectChainID, setRedirectChainID] = useState();
  const [redirectOutletName, setRedirectOutletName] = useState("");
  const [couponsProducts, setCouponsProducts] = useState([]);
  const [couponDiscountValue, setCouponDiscountValue] = useState([]);
  const [couponDiscountPercentage, setCouponDiscountPercentage] = useState([]);
  const [mapLocation, setMapLocation] = useState([]);
  const [userData, setUserData] = useState([]);
  const [featuredProductId, setFeaturedProductId] = useState();

  //console.log(userData);

  useEffect(() => {
    url.get(`/v1/general-settings/info`).then(async (res) => {
      // console.log(res);
      if (res.data.Message === "Success") {
        setFooterLogo(res?.data?.data?.media?.faviconLogoFileSource);
        setMapLocation(res?.data?.data?.info?.location.coordinates);
      } else {
        // alert("Not", res.data.data);
      }
    });

    updateCart();
    getWishItems();
  }, []);

  useEffect(() => {
    updateCart();
  }, [uid]);

  //console.log(wishCount);
  let updateCart = () => {
    if (!loggedIn) return;
    url
      .get(`/cart?customerId=${uid[0]?.userID}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setCartCount(res?.data?.data[0]?.products?.length);
        }
      })
      .catch((err) => {
        console.log(err.response.status);
        if (err.response.status === 401) {
          return Swal.fire(
            "Oops! ",
            "Session Expired! Please sign in again",
            "warning"
          ).then((result) => {
            if (result.isConfirmed) {
              localStorage.removeItem("user");
              history.push("/");
            }
          });
        }
      });
  };

  const getWishItems = () => {
    if (!loggedIn) return;
    url
      .get(`/wishlist/?userID=${uid[0]?.userID}`)
      .then((res) => {
        if (res.data.Message === "Success") {
          setWishCount(res.data.data.length);
        } else {
          //alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  if (config.debugging) console.log = function () {};

  useEffect(() => {
    document.title = config.name;
    const root = document.documentElement;
    localStorage.setItem("vendor", config.key);

    root.style.setProperty("--primary", config.primary);
    root.style.setProperty("--Secondary", config.Secondary);
    root.style.setProperty("--textSecondary", config.textSecondary);
    root.style.setProperty("--DangerSecondary", config.DangerSecondary);
    root.style.setProperty("--CardColor", config.CardColor);
    root.style.setProperty("--DiscountPercentage", config.DiscountPercentage);
    root.style.setProperty("--Text", config.Text);
  }, []);
  useFavicon(footerLogo ? footerLogo : logo);

  return (
    <>
      <Router>
        {/* <Route path="/" exact component={LandingPage} /> */}
        <ChatContext.Provider
          value={{
            chatUs,
            setChatUs,
            notificationCount,
            setNotificationCount,
            wishCount,
            setWishCount,
            cartCount,
            setCartCount,
            prodChat,
            setProdChat,
            topHeader,
            setTopHeader,
            setLoginModal,
            LoginModal,
            setRedirectUser,
            redirectUser,
            couponsProducts,
            setCouponsProducts,
            couponDiscountValue,
            setCouponDiscountValue,
            couponDiscountPercentage,
            setCouponDiscountPercentage,
            mapLocation,
            setRedirectOutletName,
            redirectOutletName,
            redirectChainID,
            setRedirectChainID,
            setUserData,
            userData,
            setFeaturedProductId,
            featuredProductId,
          }}>
          <Router>
            <Header />
            <ScrollToTop>
              <Routes />
            </ScrollToTop>
            <Footer />
          </Router>
        </ChatContext.Provider>
      </Router>
    </>
  );
}
const useFavicon = (href) => {
  useEffect(() => {
    const link =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = href;
    document.getElementsByTagName("head")[0].appendChild(link);
  }, [href]);
};
