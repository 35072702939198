import React, { useEffect, useState } from "react";

import url from "../../config/axios";
import AddAddress from "./AddAddress";

import "./Address.css";

function AddressSelector({ setModal, address, Alldet }) {
  const uid = JSON.parse(localStorage.getItem("user"));
  const [addresses, setAddresses] = useState([]);
  const [modalA, setModalA] = useState(false);


  useEffect(() => {
    if (uid) {
      url.get(`/user/address?userID=${uid[0]?.userID}`).then(async (res) => {
        if (res.data.Message === "Success") {
          setAddresses(res.data.data.addresses);
        } else {
          //  alert(res.data.data);
        }
      });
    }
  }, [uid]);


  const selectStore = (name) => {
    address(name);
    setModal(false);
  };

  const Alldetials = (det) => {
    Alldet(det);
  };

  return (
    <div className="modals" style={{ zIndex: "1" }}>
      <div className="overlay" onClick={() => setModal(false)}></div>
      <div className="address-selector">
        <p>Change Delivery Address</p>
        {/* <p>Based on your location</p> */}
        <hr />
        <div className="all-address flex-wrap" >
          {modalA && <AddAddress setModalA={setModalA} />}
          <div className="address-card" style={{ height: "auto", display: "block" }}>
            <i className="fas fa-plus" style={{ marginBottom: "0px" }} />
            <span onClick={() => setModalA(true)}>Add Address</span>
          </div>
        </div>
        <hr />
        <div className="scroll">
          {addresses.map((add, i) => (
            <div className="addressm" style={{ background: "var(--CardColor)", cursor: "pointer" }} key={i} onClick={() => { selectStore(add.address); Alldetials(add) }}>
              <i className="fas fa-location-dot" />
              <p className="m-0 ms-4">{add.address}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default AddressSelector;