import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import io from "socket.io-client";

import config from "../../config";
import url from "../../config/axios";
import { ChatContext } from "../../Contexts/ChatContext";
import adminChat from "../../Icons/Adminchat.svg";
import leftArrow from "../../Icons/leftarrow.png";
import livechatImg from "../../Icons/livechat.png";
import userChat from "../../Icons/userChat.svg";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import { useLocation } from "react-router-dom";
import "./Livechat.css";

export default function LiveLocation() {
  const { chatUs, setChatUs, prodChat, setLoginModal } =
    useContext(ChatContext);

  //console.log(prodChat);

  const [openLiveChat, setOpenLiveChat] = useState(false);
  const [chatSession, setChatSession] = useState([]);
  const [chatMsg, setChatMsg] = useState("");
  const [urlLink, setUrlLink] = useState("");
  const [allChat, setAllChat] = useState([]);

  const bottomRef = useRef(null);
  const user = JSON.parse(localStorage.getItem("user"));
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  let loggedIn = localStorage.getItem("user") ? true : false;

  //const chatWithUs = JSON.parse(localStorage.getItem("chatWithUs"));
  //const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    const currentUrl = location.pathname;
    let origin = window.location.origin;
    setUrlLink(origin + currentUrl);
    //console.log("Current URL:", currentUrl);
  }, [location]);

  // console.log(prodChat);
  // console.log(moment(allChat[0]?.createdAt)?.startOf('hour')?.fromNow());

  useEffect(() => {
    if (loggedIn) {
      const socket = io(config.baseURL, {
        query: { id: user[0]?._id, outletChainId: chainID },
        transports: ["websocket"],
      });
      socket.on("NEW_MESSAGE", (data) => {
        //console.log("NEW_MESSAGE", data);
        // console.log(data?.message);
        let x = allChat;
        x.push(data.message);
        setAllChat(x);
        message_sessions();
      });
      socket.on("connect", () => {
        //console.log("Connected")
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loggedIn) {
      message_sessions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prodChat, urlLink]);

  useEffect(() => {
    setOpenLiveChat(chatUs);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatUs]);

  const message_sessions = () => {
    let body = {
      userID: user[0]?._id,
      topic: {
        name: prodChat?.product?.name ? prodChat?.product?.name : "HELP",
        image: prodChat?.product?.image ? prodChat?.product?.image : "",
        link: urlLink,
        meta: {},
      },
    };
    //  return console.log(body);
    url.patch(`/v1/message-sessions`, body).then(async (res) => {
      // console.log(res.data.data.session._id);
      if (res.data.Message === "Success") {
        setChatSession(res?.data?.data?.session?._id);
        await AllUserChat(res?.data?.data?.session?._id);
      } else {
        console.log(res?.data);
      }
    });
  };

  const chat_message = () => {
    let body = {
      userID: user[0]?._id,
      sessionID: chatSession,
      contentGroup: [
        {
          content: chatMsg,
          contentType: "TEXT",
        },
      ],
    };
    //return console.log(body);
    url.post(`/v1/messages`, body).then((res) => {
      // console.log(res);
      if (res.data.Message === "Success") {
        setChatMsg("");
        AllUserChat(chatSession);
      } else {
        console.log(res?.data);
      }
    });
  };

  const AllUserChat = (s) => {
    let x = s ? s : chatSession;
    url
      .get(
        `/v1/messages?limit=100&skip=0&userID=${user[0]?._id}&sessionID=${x}`
      )
      .then((res) => {
        // return console.log(res?.data?.data?.messages[0].fromAdmin);
        if (res.data.Message === "Success") {
          //  console.log("previousChatLoad");
          setAllChat(res?.data?.data?.messages);
        } else {
          console.log(res?.data);
        }
      });
  };

  const something = (event) => {
    if (event.keyCode === 13) {
      chat_message();
    }
  };

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [allChat]);

  return (
    <>
      {!openLiveChat ? (
        <div
          className="cursor"
          onClick={() => {
            setOpenLiveChat(!openLiveChat);
            message_sessions();
          }}>
          <img className=" livechat" src={livechatImg} alt="" />
          <div className="colorBackground livechat-background"></div>
        </div>
      ) : (
        <div style={{ width: "auto", height: "auto", zIndex: "4" }}>
          <div
            className="overlay chatModal"
            style={{
              borderRadius: "9px",
              background: "#E5E5E5",
              width: "416px",
              height: "550px",
              marginTop: "8%",
              position: "fixed",
            }}>
            <div className="reset-contact">
              <div className="res-head">
                <div>
                  <div className="liveBody">
                    <div className="livetop secondColBackground">
                      {/* <img
                        className="leftarrow_livechat cursor"
                        src={leftArrow}
                        alt="leftArrow"
                        onClick={() => {
                          setOpenLiveChat(false);
                          setChatUs(false);
                          
                        }}
                      /> */}

                      {/* <svg className="leftarrow_livechat cursor" onClick={() =>{ setOpenLiveChat(false);setChatUs(false);}}width="8" height="13" viewBox="0 0 8 13" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path className="svgColor" fill-rule="evenodd" clip-rule="evenodd" d="M0.000113436 6.03676L1.13932e-05 6.03675C1.14093e-05 5.85193 0.0863714 5.6715 0.245821 5.53583L0.246016 5.53567L6.50915 0.217136C6.50915 0.217132 6.50916 0.217127 6.50916 0.217123C6.84992 -0.0722721 7.4134 -0.0724894 7.75399 0.217283L7.53607 0.374582L7.75382 0.217136C8.08206 0.495914 8.08206 0.940485 7.75382 1.21926L7.7538 1.21928L2.0806 6.0368L7.75334 10.8547C8.08158 11.1335 8.08158 11.5781 7.75334 11.8568L7.75325 11.8569C7.41253 12.1461 6.84964 12.1464 6.50866 11.8568L6.50865 11.8568L0.246485 6.53748C0.246479 6.53747 0.246474 6.53747 0.246469 6.53746C0.0866655 6.40173 0.000221185 6.22139 0.000113436 6.03676ZM0.296409 6.03675C0.296409 6.03675 0.296409 6.03676 0.296409 6.03677C0.296416 6.04585 0.296726 6.05492 0.297331 6.06394C0.30529 6.18268 0.364505 6.29531 0.464247 6.38002L6.72642 11.6994C6.94995 11.8892 7.31204 11.8891 7.53557 11.6994C7.7591 11.5095 7.7591 11.202 7.53557 11.0122L1.67744 6.03678L7.53606 1.06181C7.75959 0.871964 7.75959 0.564435 7.53606 0.374589C7.31292 0.184744 6.95044 0.184744 6.72691 0.374589L0.463763 5.69313C0.36432 5.77774 0.30512 5.89044 0.297216 6.00938C0.29662 6.01835 0.296315 6.02736 0.296307 6.03639C0.296307 6.0364 0.296307 6.0364 0.296307 6.03641C0.296307 6.03652 0.296307 6.03663 0.296307 6.03675L0.296409 6.03641C0.296409 6.03652 0.296409 6.03663 0.296409 6.03675Z" fill="#ffffff"/>
                      </svg> */}
                      <div className="topChat">
                        <span className="ClientNameChat">{config.name}</span>
                        <svg
                          className="leftarrow_livechat cursor"
                          onClick={() => {
                            setOpenLiveChat(false);
                            setChatUs(false);
                          }}
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0.319217 0.319217C0.744839 -0.106406 1.43512 -0.106406 1.86106 0.319217L7 5.45816L12.1389 0.319217C12.5646 -0.106406 13.2548 -0.106406 13.6808 0.319217C14.1064 0.744839 14.1064 1.43512 13.6808 1.86106L8.54184 7L13.6808 12.1389C14.1064 12.5646 14.1064 13.2548 13.6808 13.6808C13.2552 14.1064 12.5649 14.1064 12.1389 13.6808L7 8.54154L1.86106 13.6808C1.43544 14.1064 0.745153 14.1064 0.319217 13.6808C-0.106406 13.2552 -0.106406 12.5649 0.319217 12.1389L5.45816 7L0.319217 1.86106C-0.106406 1.43544 -0.106406 0.745153 0.319217 0.319217Z"
                            fill="#FF7474"
                          />
                        </svg>
                      </div>
                    </div>
                    <div className="upperDivChat">
                      <p className="Today">Today</p>
                      <p className="joined">A specialist joined the chat</p>
                    </div>

                    <div className="chats">
                      {allChat?.map((e, i) => {
                        if (e.fromAdmin)
                          return (
                            <>
                              <p className="adminTime">
                                {moment(
                                  new Date(e?.createdAt).getTime()
                                ).fromNow()}
                              </p>
                              <div className="showAdminChat">
                                <div>
                                  <img
                                    src={adminChat}
                                    alt="adminChat"
                                    className="adminImg"
                                  />
                                </div>
                                <div className="AdminChat">
                                  <span className="AdminSendMsg">
                                    {e?.contentGroup[0]?.content}
                                  </span>
                                </div>
                              </div>
                            </>
                          );
                        else
                          return (
                            <>
                              <p className="userTime">
                                {moment(
                                  new Date(e?.createdAt).getTime()
                                ).fromNow()}
                              </p>
                              <div className="showUserChat">
                                <div className="userChat">
                                  <span className="userSendMsg">
                                    {e.contentGroup[0].content}
                                  </span>
                                </div>
                                <div
                                  className="usericon"
                                  style={{ marginLeft: "10px" }}>
                                  {user[0].image ? (
                                    <img
                                      className="nav-profile-img"
                                      src={user[0].image}
                                      alt="u-img"
                                    />
                                  ) : (
                                    user[0]?.name[0]?.toUpperCase()
                                  )}
                                  {/* <img
                                    src={userChat}
                                    alt="userChat"
                                    style={{ marginLeft: "10px" }}
                                  /> */}
                                </div>
                              </div>
                            </>
                          );
                      })}
                      {user ? (
                        <>
                          {prodChat.length === 0 ? null : (
                            <div className="prodChatdiv">
                              {/* <div className="row">
                                <div className="col-4">
                                  <img src={prodChat?.product?.image ? prodChat?.product?.image : noimg} alt="prodchatimg" />

                                </div>
                                <div className="col prodChatName">
                                  <span>{prodChat?.product?.name}</span>
                                </div>
                              </div> */}
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>

                    <div className="lowerLiveChatDiv">
                      <hr style={{ margin: "0px" }} />
                      {user ? (
                        <div className="chatlower">
                          <input
                            placeholder="Send a message..."
                            type="text"
                            value={chatMsg}
                            onChange={(e) => {
                              setChatMsg(e.target.value);
                            }}
                            onKeyDown={(e) => something(e)}
                          />
                          <svg
                            className="svgSecondColor cursor"
                            width="26"
                            height="25"
                            viewBox="0 0 26 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => {
                              chat_message();
                            }}>
                            <path
                              className="svgColor"
                              d="M0.753327 0.000354056C0.515965 0.00922165 0.295513 0.125325 0.154023 0.316287C0.0127607 0.507249 -0.0340507 0.752033 0.0267824 0.981581L2.6301 10.7387L14.1252 12.4982L2.63168 14.256L0.0266768 24.0164C-0.0257036 24.2114 -0.000338357 24.4195 0.0974084 24.5964C0.194954 24.7734 0.357455 24.9056 0.550682 24.9654C0.743702 25.025 0.952609 25.0077 1.13304 24.9165L24.572 13.1971L24.5722 13.1973C24.7454 13.11 24.8802 12.9615 24.9502 12.7805C25.0201 12.5994 25.0201 12.3988 24.9502 12.2177C24.8803 12.0367 24.7454 11.8882 24.5722 11.8009L1.13324 0.0814877C1.01549 0.0231254 0.884749 -0.00471243 0.753385 0.000651305L0.753327 0.000354056Z"
                              fill="#FFCF24"
                            />
                          </svg>
                        </div>
                      ) : (
                        <div style={{ textAlign: "center" }}>
                          <Button
                            className=" gradBackground"
                            onClick={() => {
                              setLoginModal(true);
                            }}>
                            Please Login to chat
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
