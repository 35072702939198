import NavBar from "../components/Homepage/NavBar.jsx";
import Topbar from "../components/Homepage/Topbar";

function Homepage() {
  return (
    <>
  
        {/* <Topbar /> */}
        <NavBar />

    </>
  );
}

export default Homepage;
