import GoogleMapReact from "google-map-react";

import back from "../../Icons/bacj.svg";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function MapModal({ visible }) {
  const defaultProps = {
    center: {
      lat: 10.99835602,
      lng: 77.01502627,
    },
    zoom: 11,
  };

  return (
    <>
      <div className="modals modal-map">
        <div className="overlay overlay-map"></div>
        <div className="address-modal d-flex">
          <img src={back} alt="back" height="36" width="36" style={{ zIndex: 1 }} onClick={() => visible(false)} />
          <div style={{ height: "500px", width: "100%" }}>
            <GoogleMapReact bootstrapURLKeys={{ key: "" }} defaultCenter={defaultProps.center} defaultZoom={defaultProps.zoom}>
              <AnyReactComponent lat={59.955413} lng={30.337844} text="My Marker" />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    </>
  );
}

export default MapModal;
