import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import config from "../../config";
import url from "../../config/axios";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import circle from "../../images/Ellipse 91.png";
import EmptiedWishlist from "../Profile/EmptiedWishlist";

function DealsCard() {
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  //const chainID = "3P3d1s2TG2-cap_Vdf0ke"
  const [Deals, setDeals] = useState([]);
  const [noDeals, setNoDeals] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  //const [DealsColor, setDealsColor] = useState([])

  //console.log(Deals);
  useEffect(() => {
    url
      .get(
        `/v1/deals/applicable-deals?hideDisabled=1&consumerID=${config.consumerId}&outletChainID=${chainID}`
      )
      .then(async (res) => {
        if (res.data.Message === "Success" && res.data.data.length > 0) {
          // console.log(res.data.data.length);
          await setDeals(res.data.data);
          res.data.data.map((e, i) => {
            // localStorage.setItem("DealsDiscounts", JSON.stringify(e.appliesTo));
            // localStorage.setItem("selectedCategories", JSON.stringify(e.selectedCategories));
          });
        } else {
          setNoDeals(true);
        }
      });
  }, [chainID]);

  let Colors = [
    { colorA: "#ECFFEF", colorB: "#B5FFC1", colorC: "#00CD22" },
    { colorA: "#FFF1D2", colorB: "#FFDD8F", colorC: "#FFB100" },
    { colorA: "#F7EDFF", colorB: "#E3BFFF", colorC: "#AF4DFF" },
    // { colorA: "#FEF8F8", colorB: "#FFE3D4", colorC: "#FFD3D3" },
  ];

  // Colors.map((e)=>{ return setDealsColor(e) })
  //console.log(Deals);
  return (
    <div className="container">
      <div className="CustomDeal" style={{ width: "107%" }}>
        {!noDeals ? (
          <>
            {Deals?.map((e, i) => {
              let icol = Math.floor(Math.random() * Colors.length);
              // {if(!e?.image) setIsLoaded(true)}
              return (
                <Link
                  to={"/products/Deals/" + e._id}
                  style={{ textDecoration: "none" }}
                  onClick={() => {
                    localStorage.setItem(
                      "DealsDiscounts",
                      JSON.stringify(e.appliesTo)
                    );
                    localStorage.setItem(
                      "selectedCategories",
                      JSON.stringify(e.selectedCategories)
                    );
                  }}
                  key={i}>
                  <div
                    className="deals-card"
                    style={{
                      background: `radial-gradient(100% 92% at 0% 8%, ${Colors[icol].colorA} 0%, ${Colors[icol].colorB} 100%)`,
                    }}
                    key={i}>
                    <div className="upper-card">
                      <span>{e.name}</span>
                      <div
                        className="disPercentage"
                        style={{ background: `${Colors[icol].colorC}` }}>
                        {e.discountValue}% OFF
                      </div>
                    </div>
                    <div className="lower-deals-card">
                      <div className="row" style={{ width: "100%" }}>
                        <div className="col veiwProd-deal">View Products</div>

                        <div className="col deal-img-col">
                          {!e.image ? (
                            <img
                              src={noimg}
                              alt="img"
                              style={{ position: "relative" }}
                            />
                          ) : (
                            <img
                              src={e.image}
                              alt="img"
                              style={{
                                position: "relative",
                                display: !isLoaded ? "none" : "flex",
                              }}
                              onLoad={() => {
                                setIsLoaded(true);
                              }}
                            />
                          )}
                        </div>
                      </div>
                    </div>

                    {/* { !isLoaded  ? <img variant="bottom" className="noimg" src={greenPlaceholder} style={{zIndex:"10"}}  /> :"" } */}
                    {/* <div className="textcir">
                    <span>Starting at $ 12</span> 
                  </div> */}
                    {/* <img src={circle} alt="img" style={{ background: `${Colors[icol].colorC}`, width: "232.88px", height: "232.88px", borderRadius: "100%", marginTop: "-56.58px" }} /> */}
                  </div>
                </Link>
              );
            })}
          </>
        ) : (
          <EmptiedWishlist message={"Deal"} />
        )}
      </div>
    </div>
  );
}

export default DealsCard;
