import React, { useState } from "react";

import url from "../../config/axios";
import AddressModal from "./AddressModal";

function AddAddress() {
  const [addAddress, setAddAddress] = useState(false);
  const [name, setName] = useState("");
  const [contact, setContact] = useState(0);
  const [sat, setSat] = useState(false);
  const [sun, setSun] = useState(false);
  const [type, setType] = useState("");
  const [address, setAddress] = useState("");
  const [zipcode, setZipcode] = useState(0);
  const [city, setCity] = useState("");
  const [locality, setLocality] = useState("");
  const [cState, setCState] = useState("");
  const [country] = useState("India");
  const [aDefault, setADefault] = useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  let body = {
    type: [type],
    default: aDefault,
    openWeekends: [
      {
        saturday: sat,
      },
      {
        sunday: sun,
      },
    ],
    name: name,
    contact: contact,
    address: address,
    landmark: locality,
    city: city,
    zipcode: zipcode,
    state: cState,
    country: country,
  };

  const addNewAddress = () => {
    url
      .post(`/user/address?userID=${user[0]?.userID}`, body)
      .then((res) => {

        if (res.data.Message === "Success") {
          window.location.reload(false);
        } else {

          //  alert("Not Update", res.data.data);
        }
      })
      .catch((e) => console.log("update", e));
  };

  return (
    <div className="dashboard">
      {addAddress && <AddressModal addAddress={setAddAddress} />}
      <span>New Address</span>
      <div className="add">
        <div className="address-details">
          <div className="details1">
            <span>
              Name*
              <input type="text" name="" id="" placeholder="" onChange={(e) => setName(e.target.value)} />
              Please Enter Your Full Name
            </span>
            <span>
              Contact Number*
              <input type="text" name="" id="" placeholder="" onChange={(e) => setContact(e.target.value)} />
            </span>
          </div>
          <div className="details2">
            <span onClick={() => setAddAddress(true)}>
              <i className="fas fa-map-marker-alt"></i>
              <input type="text" placeholder="Location on Map" />
            </span>
            <span>
              <input type="text" placeholder="Address*" onChange={(e) => setAddress(e.target.value)} />
            </span>
            <span>
              <input type="text" placeholder="Locality/Town" onChange={(e) => setLocality(e.target.value)} />
            </span>
            <span>
              <input type="text" placeholder="City" onChange={(e) => setCity(e.target.value)} />
            </span>
            <span>
              <span>
                <input name="" id="" placeholder="Zipcode*" onChange={(e) => setZipcode(e.target.value)} />
              </span>
              <span>
                <input name="" id="" placeholder="State*" onChange={(e) => setCState(e.target.value)} />
              </span>
            </span>
          </div>
          <button onClick={() => addNewAddress()}>SAVE ADDRESS</button>
        </div>
        <div className="address-type">
          <p>Type of Address</p>
          <span>
            <span className="mb-3">
              <input type="radio" name="addressType" id="home" onClick={() => setType("Home")} />
              <label className="m-0" for="home">
                Home
              </label>
            </span>
            <span className="mb-3">
              <input type="radio" name="addressType" id="office" onClick={() => setType("Office")} />
              <label className="m-0" for="office">
                Office
              </label>
            </span>
          </span>
          <p>Is your office open on weekends? *</p>
          <span>
            <span className="mb-3">
              <input type="checkbox" name="default" id="saturday" onClick={() => setSat(!sat)} />
              <label className="m-0" for="saturday">
                Open on Saturdays
              </label>
            </span>
            <span className="mb-3">
              <input type="checkbox" name="default" id="sunday" onClick={() => setSun(!sun)} />
              <label className="m-0" for="sunday">
                Open on Sundays
              </label>
            </span>
            <span className="mb-3">
              <input type="checkbox" name="default" id="" onClick={() => setADefault(!aDefault)} />
              <p className="m-0">Make this my default address</p>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
}

export default AddAddress;
