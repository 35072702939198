import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import url from "../../config/axios";
import { ChatContext } from "../../Contexts/ChatContext";
//import img from "../../images/blackdiamondflower 1.png";
import ProductCard from "./ProductCard";
import Carousel from "better-react-carousel";

function ProductCards(props) {
  const { setWishCount } = useContext(ChatContext);

  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  //  const chainID = "3P3d1s2TG2-cap_Vdf0ke"

  const [product, setProduct] = useState([]);
  const [wish, setWish] = useState([]);
  const uid = JSON.parse(localStorage.getItem("user"));
  let loggedIn = localStorage.getItem("user") ? true : false;

  //console.log(product)

  useEffect(() => {
    GetProduct();
    getWishItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetProduct = () => {
    url
      .get(`/inventory/popular/?outletChainID=${chainID}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setProduct(res.data.data);
        } else {
          // Swal.fire(
          //   'Popular Product',
          //   'Not Found ',
          //   'error'
          // )
        }
      });
  };

  const getWishItems = () => {
    if (!loggedIn) return;
    url
      .get(`/wishlist/?userID=${uid[0]?.userID}`)
      .then((res) => {
        if (res.data.Message === "Success") {
          setWish(res.data.data);
          setWishCount(res.data.data.length);
          if (res.data.data.length < 1) setWish([]);
        } else {
          //alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const addFav = (e, prod) => {
    if (!loggedIn) return;

    if (e) {
      let WBody = {
        outletId: chainID,
        customerId: uid[0]?.userID,
        userID: uid[0]?.userID,
        product: {
          productID: prod.productID,
          quantity: prod?.invertory,
          originalPrice: prod?.originalPrice,
          outletChainID: chainID,
          variants: [prod?.selectedVariantData],
          product: {
            productID: prod.productID,
            name: prod.productname,
            quantity: prod?.invertory,
            image: prod?.img,
            price: prod?.vPrice ? prod?.vPrice : prod.originalPrice,
            discountPrice: prod?.discountPrice,
            brandName: prod.brandName,
            brandID: prod.brandID,
            invertory: prod?.invertory,
            purchaseQuantity: 1,
          },
        },
        status: true,
      };

      // return console.log(WBody);
      url
        .post(`/wishlist/`, WBody)
        .then(async (res) => {
          if (res.data.Message === "Failure") console.log(res.data);
          else {
            getWishItems();
          }
        })
        .catch((e) => console.log(e));
    } else {
      let x = wish;
      let id = x.findIndex((k) => k.product.productID === prod.productID);

      url
        .delete(`/wishlist/?wishlistID=${x[id]?.wishlistID}`)
        .then((res) => {
          if (res.data.Message === "Success") {
            x.splice(id, 1);
            // setWish(x)
            getWishItems();
          } else console.log(res.data);
        })
        .catch((e) => console.log(e));
    }
  };

  return (
    <>
      {product?.length === 0 ? null : (
        <div className="productcards">
          <div className="title">
            <span>{props.title}</span>
            {product.length > 3 && (
              <Link
                to={"/products/popular/" + props.title}
                style={{ textDecoration: "none" }}>
                <div>
                  <span
                    className="secondColor"
                    style={{ color: "#22B34B", cursor: "pointer" }}
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Click to Veiw All">
                    View All
                  </span>
                </div>
              </Link>
            )}
          </div>
          <div className="main-cards">
            <Carousel
              cols={4}
              rows={1}
              gap={12}
              width="100"
              style={{ width: "100%" }}>
              {product.slice(0, 5).map((prd, i) => {
                return (
                  <Carousel.Item style={{ width: "100%" }}>
                    <ProductCard
                      productID={prd?.productID}
                      path={prd?.product?.name}
                      discount={Math.round(
                        ((prd?.originalPrice - prd?.discountPrice) /
                          prd?.originalPrice) *
                          100
                      )}
                      img={prd?.product?.image}
                      productname={prd?.product?.name}
                      brandName={prd?.product?.brandName}
                      brandID={prd?.product?.brandID}
                      discountPrice={prd?.discountPrice}
                      originalPrice={prd?.originalPrice}
                      vPrice={
                        prd.originalPrice
                          ? prd.originalPrice
                          : prd?.variants[0]?.price
                      }
                      currency={
                        prd?.variants[0]?.pricingInfo?.currency
                          ? prd?.variants[0]?.pricingInfo?.currency === "USD"
                            ? "$"
                            : prd?.variants[0]?.pricingInfo?.currency + "$"
                          : "$"
                      }
                      vWeight={prd?.variants[0]?.weight}
                      vUom={prd?.variants[0]?.uom}
                      plantType={prd?.product?.cannabisType}
                      key={i}
                      cbd={prd?.product.cbd}
                      thc={prd?.product.thc}
                      wish={wish}
                      addFav={addFav}
                      invertory={prd?.quantity}
                      selectedVariantData={prd?.variants[0]}
                    />
                  </Carousel.Item>
                );
              })}
            </Carousel>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductCards;
