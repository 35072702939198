import React, { useContext, useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";
import { useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import config from "../../config";
import url from "../../config/axios";
import { ChatContext } from "../../Contexts/ChatContext";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import carticon from "../../Icons/cartIcon.svg";
import greenPlaceholder from "../../images/Green_placeholder.png";
import wishicon from "../../Icons/wishlistIcon.svg";
import Spinner from "react-bootstrap/Spinner";
import ProductCards from "../Home/ProductCards";

function ProductPage(props) {
  let history = useHistory();

  const {
    setChatUs,
    setWishCount,
    cartCount,
    setCartCount,
    setProdChat,
    setLoginModal,
    redirectUser,
    redirectChainID,
    userData,
    featuredProductId,
  } = useContext(ChatContext);

  const [productDetails, setProductDetails] = useState();
  const [getCartProduction, setGetCartProduction] = useState([]);
  const [count, setCount] = useState(1);
  const [products, setProducts] = useState([]);
  const [wishList, setWishList] = useState([]);
  const [selectedVariantData, setSelectedVariantData] = useState({});
  const [valCount, setValCount] = useState(false);
  const [loader, setLoader] = useState(false);
  const [skel, setskel] = useState(true);
  const [cartLoader, setCartLoader] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [foundProduct, setFoundProduct] = useState([]);

  // console.log(selectedVariantData);
  //console.log(productDetails);

  const uid = JSON.parse(localStorage.getItem("user"));
  localStorage.setItem(
    "user",
    JSON.stringify(redirectUser.length !== 0 ? redirectUser : uid)
  );
  const chainID = redirectChainID
    ? redirectChainID
    : JSON.parse(localStorage.getItem("outletChainID"));
  const outletname = JSON.parse(localStorage.getItem("outletname"));
  const token = JSON.parse(localStorage.getItem("Etoken"));
  const DealsDiscounts = JSON.parse(localStorage.getItem("DealsDiscounts"));

  useEffect(() => {
    let dis = DealsDiscounts ? Object.keys(DealsDiscounts) : null;
    let disEntries = DealsDiscounts ? Object.entries(DealsDiscounts) : null;
    let pd = DealsDiscounts ? [productDetails] : [];
    let pf = foundProduct;

    pd?.map((e) => {
      let xd = dis?.filter((d) => d === e?.product?.productID);

      let prod = pd?.filter((p) => p?.product?.productID === xd[0]);
      pf.push(prod);

      disEntries?.map((e1, i1) => {
        pf.map((e2, i2) => {
          if (e1[0] === e2[0]?.product?.productID) {
            e2[0].discountPercentage = e1[1].discountValue;
            e2[0].discountPrice = e2[0].originalPrice
              ? e2[0].originalPrice
              : e2[0].variants[0].price -
                (e2[0].originalPrice
                  ? e2[0].originalPrice
                  : (e2[0].variants[0].price * e1[1].discountValue) / 100);
          }
        });
      });
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DealsDiscounts]);

  useEffect(() => {
    if (userData) {
      GetCart();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  // useEffect(() => {
  //   GetProductDetails();
  //   getWishItems();
  //   GetCart();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    GetProductDetails();
    getWishItems();
    GetCart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.productpage]);

  if (uid === null) {
    localStorage.removeItem("user");
  }
  let loggedIn =
    localStorage.getItem("user") && uid?.length !== 0 ? true : false;

  useEffect(() => {
    localStorage.setItem("cart", JSON.stringify(products));
  }, [products]);

  useEffect(() => {
    localStorage.setItem("cartCount", JSON.stringify(cartCount));
  }, [cartCount]);

  const checkcount = (value) => {
    setCartCount(value);
  };

  const GetProductDetails = () => {
    url
      .get(`/inventory/id?id=${props?.productpage}&outletChainID=${chainID}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          // console.log(res.data.data);
          setskel(false);
          // setIsLoaded(true)
          setProductDetails(res.data.data);
          setProdChat(res.data.data);
          setSelectedVariantData(res.data.data.variants[0]);
        } else {
        }
      });
  };

  const GetCart = () => {
    if (!loggedIn) return;
    url
      .get(
        `/cart?customerId=${
          userData[0]?.userID ? userData[0]?.userID : uid[0]?.userID
        }`
      )

      .then(async (res) => {
        //  console.log(res.data.data);
        if (res.data.Message === "Success") {
          setGetCartProduction(res.data.data);
          checkcount(res?.data?.data[0]?.products?.length);
          setCartLoader(true);
          //console.log(res.data.data);
        } else {
          setCartLoader(false);
        }
      });
  };

  let selectedVarientQuantity = selectedVariantData?.quantity
    ? selectedVariantData?.measured_by == "UNIT"
      ? selectedVariantData?.quantity
      : selectedVariantData?.weight
    : productDetails?.quantity;

  // console.log(selectedVarientQuantity);

  const tosterCart = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-right",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: "Added to Cart",
    });
  };

  const tosterWishlist = () => {
    if (loggedIn) {
      const Toast = Swal.mixin({
        toast: true,
        position: "bottom-right",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "success",
        title: "Added to Wishlist",
      });
    }
  };

  const addToCart = async () => {
    if (!loggedIn) return setLoginModal(true);

    if (productDetails?.quantity <= 0)
      return Swal.fire("Missing ", "Out of stock", "warning");

    let obj = {
      productID: productDetails.product.productID,
      variant: selectedVariantData,
      product: {
        productID: productDetails.product.productID,
        name: selectedVariantData?.name
          ? selectedVariantData?.name
          : productDetails.product.name,
        quantity: selectedVariantData?.quantity
          ? selectedVariantData?.quantity
          : productDetails?.quantity,
        brandName: productDetails.product.brandName,
        brandID: productDetails.product.brandID,
        image: selectedVariantData?.image
          ? selectedVariantData?.image
          : productDetails.product.image,
        price: selectedVariantData?.price
          ? selectedVariantData?.price
          : productDetails?.originalPrice,
        invertory: productDetails?.quantity,
        discountPrice: !selectedVariantData?.price
          ? productDetails?.discountPrice
            ? parseFloat(
                productDetails?.originalPrice -
                  (productDetails?.originalPrice *
                    productDetails.discountPercentage) /
                    100
              )?.toFixed(2)
            : 0
          : productDetails?.discountPrice
          ? parseFloat(
              selectedVariantData?.price -
                (selectedVariantData?.price *
                  productDetails.discountPercentage) /
                  100
            )?.toFixed(2)
          : 0,
        purchaseQuantity: count,
      },

      totalPrice:
        (selectedVariantData?.price
          ? selectedVariantData?.price
          : productDetails?.originalPrice) * count,

      status: true,
    };

    let found = false;
    getCartProduction.filter((k) => {
      // console.log(k);
      k.products.filter((k1) => {
        if (k1?.variant?.weight) {
          if (
            k1.productID === obj.product.productID &&
            k1?.variant?.weight === obj?.variant?.weight
          ) {
            found = true;
            GetCart();
          } else {
          }
        } else {
          if (k1.productID === obj.product.productID) {
            found = true;
            GetCart();
          } else {
          }
        }
      });
    });
    // return console.log(found);
    //return console.log(obj);
    if (!found) {
      let ft = [];
      // console.log("not founddddddddddddddddddd");

      getCartProduction.map((k, i) => {
        ft.push(...k.products, obj);
      });
      //console.log(ft);
      // return console.log(ft.length === 0 ? [obj] : ft);
      url
        .put(
          `/v1/cart/update-products`,
          {
            products: ft.length === 0 ? [obj] : ft,
            totalPrice: 0,
            status: true,
          },
          {
            headers: {
              auth: token,
            },
          }
        )
        .then(async (res) => {
          //  console.log(res);
          if (res.data.Message === "Success") {
            //  console.log(found + "new cart created");
            setCartLoader(false);
            // AddToCart(obj);
            setCount(1);
            tosterCart();
            await GetCart();
          } else {
            setCartLoader(true);
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });

            Toast.fire({
              icon: "warning",
              title: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err.response.status);
          if (err.response.status === 401) {
            return Swal.fire(
              "Oops! ",
              "Session Expired! Please sign in again.",
              "warning"
            ).then((result) => {
              if (result.isConfirmed) {
                localStorage.removeItem("user");
                history.push("/");
              }
            });
          }
        });
    } else {
      let ft = [];
      getCartProduction.map((k, i) => {
        k.products.map((k1, i) => {
          if (k1.productID === obj.product.productID) {
            //k1.product.quantity = k1.product.quantity + count;
            k1.product.purchaseQuantity = k1.product.purchaseQuantity + count;
            ft.push(k);
            //console.log(k);
          }
        });
        //return console.log(ft[0]);
        return url
          .put(
            `/v1/cart/update-products`,
            {
              products: ft[0].products,
              totalPrice: 0,
              status: true,
            },
            {
              headers: {
                auth: token,
              },
            }
          )
          .then(async (res) => {
            // console.log(res);
            if (res.data.Message === "Success") {
              setCartLoader(false);
              await GetCart();
              // console.log(res.data.toSend.data);
              const Toast = Swal.mixin({
                toast: true,
                position: "bottom-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "warning",
                title: "Cart updated successfully",
              });
            } else {
              setCartLoader(true);
              const Toast = Swal.mixin({
                toast: true,
                position: "bottom-right",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                  toast.addEventListener("mouseenter", Swal.stopTimer);
                  toast.addEventListener("mouseleave", Swal.resumeTimer);
                },
              });

              Toast.fire({
                icon: "warning",
                title: res.data.message,
              });
            }
          })
          .catch((err) => {
            console.log(err.response.status);
            if (err.response.status === 401) {
              return Swal.fire(
                "Oops! ",
                "Session Expired! Please sign in again.",
                "warning"
              ).then((result) => {
                if (result.isConfirmed) {
                  localStorage.removeItem("user");
                  history.push("/");
                }
              });
            }
          });
      });
    }
    // console.log(id);
  };

  const chkCart = () => {
    if (!uid) return setLoginModal(true);
    if (productDetails?.quantity === 0)
      Swal.fire("Missing ", "Out of stock", "warning");
  };

  const chkWish = () => {
    if (!uid) return setLoginModal(true);
    if (productDetails?.quantity === 0)
      Swal.fire("Missing ", "Out of stock", "warning");
  };

  const getWishItems = () => {
    if (!uid) return;
    url
      .get(`/wishlist/?userID=${uid[0]?.userID}`)
      .then((res) => {
        if (res.data.Message === "Success") {
          setWishList(res.data.data);
          setWishCount(res.data.data.length);
          setLoader(false);
        }
      })
      .catch((e) => console.log(e));
  };

  const addToWishlist = () => {
    if (!uid) return setLoginModal(true);

    let found = false;
    wishList.map((e) => {
      //console.log(productDetails);
      if (e?.product?.variants[0]?.weight) {
        if (
          e.product.productID === productDetails.productID &&
          e?.product?.variants[0]?.weight === selectedVariantData.weight
        ) {
          e.product.quantity += count;
          found = true;
          tosterWishlist();
        } else {
          // existWishlist();
        }
      } else {
        if (e.product.productID === productDetails.productID) {
          e.product.quantity += count;
          found = true;
          tosterWishlist();
        } else {
          // existWishlist();
        }
      }
      return e;
    });
    if (found) {
      if (loggedIn) {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "warning",
          title: "Wishlist already exists",
        });
      }
    } else {
      let WBody = {
        consumerId: config.consumerId,
        outletId: chainID,
        customerId: uid[0]?.userID,
        outletName: outletname,
        userID: uid[0]?.userID,
        product: {
          productID: productDetails.product.productID,
          quantity: selectedVariantData?.quantity
            ? selectedVariantData?.quantity
            : productDetails?.quantity,
          originalPrice: productDetails?.originalPrice,
          outletChainID: chainID,
          variants: selectedVariantData ? [selectedVariantData] : [],
          product: {
            productID: productDetails.product.productID,
            name: selectedVariantData?.name
              ? selectedVariantData?.name
              : productDetails.product.name,
            quantity: selectedVariantData?.quantity
              ? selectedVariantData?.quantity
              : productDetails?.quantity,
            brandName: productDetails.product.brandName,
            brandID: productDetails.product.brandID,
            image: selectedVariantData?.image
              ? selectedVariantData?.image
              : productDetails.product.image,
            price: selectedVariantData?.price
              ? selectedVariantData?.price
              : productDetails?.originalPrice,
            invertory: productDetails?.quantity,
            discountPrice: !selectedVariantData?.price
              ? productDetails?.discountPrice
                ? parseFloat(
                    productDetails?.originalPrice -
                      (productDetails?.originalPrice *
                        productDetails.discountPercentage) /
                        100
                  )?.toFixed(2)
                : null
              : productDetails?.discountPrice
              ? parseFloat(
                  selectedVariantData?.price -
                    (selectedVariantData?.price *
                      productDetails.discountPercentage) /
                      100
                )?.toFixed(2)
              : 0,
            purchaseQuantity: count,
          },
        },
        totalPrice:
          (selectedVariantData?.price
            ? selectedVariantData?.price
            : productDetails?.originalPrice) * count,

        status: true,
      };

      // return console.log(WBody);
      url
        .post(`/wishlist/`, WBody)

        .then(async (res) => {
          getWishItems();
          setLoader(true);
          tosterWishlist();
        })
        .catch((e) => console.log(e));
    }
  };

  const CountHandle = (e) => {
    // const value = e.replace(/\D/g, "");
    const intRegex = /^[0-9]*$/;
    const regex = /^[0-9]*[.]?[0-9]*$/;

    if (selectedVariantData?.measured_by !== "UNIT" && regex.test(e)) {
      if (e <= selectedVarientQuantity) {
        const validated = e.match(/^(\d*\.{0,1}\d{0,2}$)/);
        if (validated) {
          setCount(e);
          setValCount(false);
        }
      } else {
        setValCount(true);
      }
    } else {
      if (intRegex.test(e)) {
        if (e <= selectedVarientQuantity) {
          setCount(parseInt(e));
          setValCount(false);
        } else {
          setValCount(true);
        }
      }
    }
  };
  //  console.log(productDetails);

  return (
    <div className="single-product">
      <>
        <div className="prd-details">
          <div className="prd-image">
            {productDetails?.product?.image ? (
              <img
                src={
                  selectedVariantData?.image
                    ? selectedVariantData?.image
                    : productDetails?.product?.image
                }
                style={{ display: !isLoaded ? "none" : "flex" }}
                alt=""
                onLoad={() => {
                  setIsLoaded(true);
                }}
              />
            ) : (
              <img
                src={noimg}
                className="pNoImg"
                alt=""
                // style={{ display: !isLoaded ? "none" : "flex" }}
              />
            )}
            {!isLoaded && productDetails?.product?.image ? (
              <Spinner
                style={{ width: "100px", height: "100px", marginTop: "100px" }}
                animation="grow"
                variant="primary"
                className="colorBackground"
              />
            ) : null}
          </div>
          <div className="prd-desc">
            {!skel ? (
              <span>
                {selectedVariantData?.name
                  ? selectedVariantData?.name
                  : productDetails?.product?.name}
              </span>
            ) : (
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} style={{ borderRadius: "10px" }} />
              </Placeholder>
            )}
            {!skel ? (
              <span className="potency">
                {/* {productDetails?.thc ? */}
                <p>
                  {/* <img src={downArrow} alt="down" /> */}
                  <svg
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="svgColor"
                      d="M19.6902 0.639884C20.46 0.639884 20.9411 1.47322 20.5562 2.13988L11.6484 17.5687C11.2635 18.2354 10.3012 18.2354 9.91633 17.5687L1.00849 2.13988C0.623593 1.47322 1.10472 0.639884 1.87452 0.639884L19.6902 0.639884Z"
                      fill="#1F6355"
                    />
                  </svg>
                  &nbsp;
                  {productDetails?.product?.thc
                    ? productDetails?.product?.thc + "%"
                    : "N/A"}{" "}
                  THC
                </p>

                <p>
                  <svg
                    width="21"
                    height="19"
                    viewBox="0 0 21 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      className="svgColor"
                      d="M1.91333 18.2927C1.14353 18.2927 0.662403 17.4594 1.0473 16.7927L9.95514 1.36391C10.34 0.697245 11.3023 0.697245 11.6872 1.36391L20.595 16.7927C20.9799 17.4594 20.4988 18.2927 19.729 18.2927L1.91333 18.2927Z"
                      fill="#1F6355"
                    />
                  </svg>
                  &nbsp;
                  {productDetails?.product?.cbd
                    ? productDetails?.product?.cbd + "%"
                    : "N/A"}{" "}
                  CBD
                </p>
                {/* :null} */}
              </span>
            ) : (
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={3} style={{ borderRadius: "10px" }} />
                <Placeholder xs={3} style={{ borderRadius: "10px" }} />
              </Placeholder>
            )}
            {!skel ? (
              productDetails?.product?.brandID ? (
                <span>
                  <Link
                    to={
                      "/products/manufacturers/" +
                      productDetails?.product?.brandID
                    }
                    style={{ textDecoration: "none" }}>
                    Brand:{" "}
                    <mark
                      className="secondColor"
                      style={{ background: "none" }}>
                      {productDetails?.product?.brandName
                        ? productDetails?.product?.brandName
                        : "N/A"}
                    </mark>
                  </Link>
                </span>
              ) : (
                <span>
                  <span className="pBrand secondColor">Brand:</span>{" "}
                  <mark style={{ background: "none" }}>
                    {productDetails?.product?.brandName
                      ? productDetails?.product?.brandName
                      : "N/A"}
                  </mark>
                </span>
              )
            ) : (
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={4} style={{ borderRadius: "10px" }} />
              </Placeholder>
            )}

            <div className="row varients" style={{ marginTop: "5%" }}>
              <div className="col">
                <div
                  className="count countProdPage"
                  style={{ display: "flex", width: "53%", height: "32px" }}>
                  {count <= 1 || !count ? (
                    <button
                      className="minus"
                      disabled
                      style={{
                        background: "gray",
                        width: "22px",
                        height: "23px",
                      }}
                      onClick={() => {
                        if (count - 1 > 0) setCount(count - 1);
                      }}>
                      {" "}
                      <span style={{ color: "black", fontSize: "27px" }}>
                        {" "}
                        -
                      </span>
                    </button>
                  ) : (
                    <button
                      className="minus"
                      style={{ width: "22px", height: "23px" }}
                      onClick={() => {
                        if (count - 1 > 0) {
                          setCount(count - 1);
                          setValCount(false);
                        }
                      }}>
                      {" "}
                      <span style={{ color: "black", fontSize: "27px" }}>
                        {" "}
                        -{" "}
                      </span>
                    </button>
                  )}

                  <input
                    type="tel"
                    value={count && selectedVarientQuantity > 0 ? count : 0}
                    onChange={(e) => {
                      CountHandle(e.target.value);
                    }}
                  />

                  <button
                    className="plus"
                    onClick={() => {
                      if (count + 1 > 0) {
                        setCount(parseInt(count) + 1);
                      }
                      if (count >= productDetails?.quantity) {
                        setValCount(true);
                        setCount(parseInt(productDetails?.quantity));
                      }
                      // else setValCount(false)
                    }}>
                    {" "}
                    <span
                      style={{
                        color: "black",
                        fontSize: "27px",
                        fontWeight: "300",
                      }}>
                      {" "}
                      +{" "}
                    </span>
                  </button>
                </div>{" "}
                {valCount ? (
                  <span style={{ fontSize: "15px", color: "rgb(255, 99, 99)" }}>
                    Available Stock Qty : {selectedVarientQuantity}
                    <br />
                  </span>
                ) : null}
                {!selectedVarientQuantity ? (
                  <span style={{ fontSize: "15px", color: "rgb(255, 99, 99)" }}>
                    {" "}
                    Out of Stock
                    <br />
                  </span>
                ) : null}
                <span
                  onClick={() => {
                    setChatUs(true);
                  }}
                  className="cursor"
                  style={{ marginTop: "0px", fontSize: "14px" }}>
                  Chat with us
                </span>
              </div>

              <div className="col">
                {productDetails?.variants?.length === 0 ||
                !productDetails?.variants ? (
                  productDetails?.discountPrice ? (
                    <div className="prd-price" style={{ display: "block" }}>
                      {!skel ? (
                        <span style={{ width: "100%" }}>
                          ${" "}
                          {(
                            productDetails?.originalPrice -
                            (productDetails?.originalPrice *
                              productDetails.discountPercentage) /
                              100
                          )?.toFixed(2)}{" "}
                        </span>
                      ) : (
                        <Placeholder as={Card.Title} animation="glow">
                          <Placeholder
                            xs={4}
                            style={{ borderRadius: "10px" }}
                          />
                        </Placeholder>
                      )}
                      {!skel ? (
                        <>
                          <span
                            style={{
                              textDecoration: "line-through",
                              marginLeft: "10px",
                              marginRight: "5px",
                            }}>
                            $ {(productDetails?.originalPrice).toFixed(2)}{" "}
                          </span>
                          <span style={{ color: "blue", fontSize: "12px" }}>
                            {productDetails?.discountPercentage}% OFF
                          </span>
                        </>
                      ) : (
                        <Placeholder as={Card.Title} animation="glow">
                          <Placeholder
                            xs={4}
                            style={{ borderRadius: "10px" }}
                          />
                        </Placeholder>
                      )}
                    </div>
                  ) : (
                    <div className="prd-price">
                      {productDetails?.originalPrice ? (
                        <span
                          style={{
                            lineHeight: "0px",
                            width: "100%",
                          }}>
                          $ {productDetails?.originalPrice?.toFixed(2)}
                        </span>
                      ) : null}
                    </div>
                  )
                ) : (
                  <div style={{ cursor: "pointer", display: "flex" }}>
                    {!skel ? (
                      <div className="row">
                        {productDetails?.variants?.map((item) => {
                          return (
                            <div
                              // varientShadow
                              className=""
                              style={{
                                borderWidth: 5,
                                marginBottom: " 5%",
                                border:
                                  selectedVariantData.weight === item.weight
                                    ? " 3px solid black"
                                    : "3px solid gray",
                                boxSizing: "borderBox",
                                borderRadius: 12,
                                justifyContent: "center",
                                alignItems: "center",
                                width: productDetails?.discountPrice
                                  ? "120px"
                                  : "100px",
                                height: productDetails?.discountPrice
                                  ? "120px"
                                  : "100px",
                                marginRight: "10px",
                                padding: "0px",
                                display: "flex",
                              }}
                              onClick={() => setSelectedVariantData(item)}>
                              <div className="col ">
                                {/* <div style={{ textAlign: "center" }}>
                                  <span
                                    style={{
                                      lineHeight: "0px",
                                      color:
                                        selectedVariantData?.weight ===
                                        item?.weight
                                          ? "#121212"
                                          : "grey",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                    }}>
                                    {item?.weight ? item?.weight : "Item"}
                                  </span>
                                  <span
                                    style={{
                                      lineHeight: "0px",
                                      color:
                                        selectedVariantData.weight ===
                                        item.weight
                                          ? "#121212"
                                          : "grey",
                                      fontSize: "12px",
                                      fontWeight: "500",
                                    }}>
                                    {item?.weight ? item.uom : ""}
                                  </span>
                                </div> */}
                                <div
                                  style={{
                                    marginTop: "0px",
                                    textAlign: "center",
                                  }}>
                                  {productDetails?.discountPrice ? (
                                    <span
                                      style={{
                                        lineHeight: "0px",
                                        color:
                                          selectedVariantData?.weight ===
                                          item?.weight
                                            ? "#121212"
                                            : "grey",
                                        fontSize: "13px",
                                        fontWeight: "400",
                                      }}>
                                      {selectedVariantData?.pricingInfo
                                        ?.currency
                                        ? selectedVariantData?.pricingInfo
                                            ?.currency === "USD"
                                          ? "$"
                                          : selectedVariantData?.pricingInfo
                                              ?.currency + "$"
                                        : "$ "}{" "}
                                      {(
                                        item?.price -
                                        (item?.price *
                                          productDetails?.discountPercentage) /
                                          100
                                      )?.toFixed(2)}
                                      <br />
                                    </span>
                                  ) : null}

                                  <span
                                    style={{
                                      color:
                                        selectedVariantData?.weight ===
                                        item?.weight
                                          ? "#121212"
                                          : "grey",
                                      fontSize: "13px",
                                      fontWeight: "400",
                                      textDecoration:
                                        productDetails?.discountPrice
                                          ? "line-through"
                                          : "none",
                                    }}>
                                    {selectedVariantData?.pricingInfo?.currency
                                      ? selectedVariantData?.pricingInfo
                                          ?.currency === "USD"
                                        ? "$"
                                        : selectedVariantData?.pricingInfo
                                            ?.currency + "$"
                                      : "$ "}{" "}
                                    {parseFloat(item?.price)?.toFixed(2)}{" "}
                                  </span>

                                  {productDetails.discountPercentage ? (
                                    <span
                                      className="secondColor"
                                      style={{
                                        fontSize: "13px",
                                      }}>
                                      <br />
                                      {productDetails.discountPercentage
                                        ? productDetails.discountPercentage +
                                          "%" +
                                          "OFF"
                                        : null}
                                    </span>
                                  ) : null}

                                  {" / "}
                                  {selectedVariantData?.measured_by === "UNIT"
                                    ? "item"
                                    : item.uom}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <Placeholder as={Card.Title} animation="glow">
                        <Placeholder xs={3} style={{ borderRadius: "10px" }} />
                        <Placeholder
                          xs={3}
                          style={{ borderRadius: "10px", height: "32px" }}
                        />
                      </Placeholder>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="row Sprod_btn">
              <span style={{ display: "flex", marginTop: "7%" }}>
                {count === 0 || !count || !selectedVarientQuantity ? (
                  <>
                    <div className="col">
                      <button
                        style={{
                          background: "rgba(186, 186, 186, 186)",
                          marginBottom: "20px",
                        }}
                        onClick={() => {
                          chkCart();
                        }}>
                        <img
                          style={{
                            paddingRight: "0px",
                            marginRight: "24px",
                            marginLeft: "-24px",
                            paddingBottom: "6px",
                          }}
                          src={carticon}
                          alt="cicon"
                        />
                        Add to Cart
                      </button>
                    </div>
                    <div className="col">
                      <button
                        style={{ background: "rgba(186, 186, 186, 186)" }}
                        onClick={() => {
                          chkWish();
                        }}>
                        <img
                          style={{
                            paddingRight: "15px",
                            paddingBottom: "6px",
                            background: "rgba(186, 186, 186, 0.53)",
                          }}
                          src={wishicon}
                          alt="wicon"
                        />
                        Add to Wishlist
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="col">
                      <button
                        onClick={() => {
                          addToCart();
                          checkcount(cartCount);
                        }}>
                        <img
                          style={{
                            paddingRight: "0px",
                            marginRight: "24px",
                            marginLeft: "-24px",
                            paddingBottom: "6px",
                          }}
                          src={carticon}
                          alt="cicon"
                        />
                        Add to Cart
                      </button>
                    </div>
                    <div className="col">
                      <button
                        disabled={loader}
                        className="wishlistbtn dangerBackground"
                        onClick={() => {
                          addToWishlist();
                          setLoader(false);
                        }}>
                        <img
                          style={{ paddingRight: "15px", paddingBottom: "6px" }}
                          src={wishicon}
                          alt="wicon"
                        />
                        Add to Wishlist
                      </button>
                    </div>
                  </>
                )}
              </span>
            </div>
            <hr />
            <div className="desc">
              <span>Description</span>
              <span>
                {productDetails?.product?.shortDescription
                  ? productDetails?.product?.shortDescription
                  : "N/A"}
              </span>
            </div>
            <hr />
            <span style={{ color: "#000" }}>Effects</span>
            {!productDetails?.product?.effects ? (
              <div style={{ color: "#a6a6a6", fontSize: "18px" }}>
                <br /> N/A
              </div>
            ) : (
              <div style={{ display: "flex" }}>
                {productDetails?.product?.effects?.map((e) => {
                  return (
                    <div
                      className="effect"
                      style={{ display: e.length === 0 ? "none" : "flex" }}>
                      {" "}
                      <p> {e} </p>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </>

      <div
        className="container singleProductPageFeaturedContainer-phone"
        style={{ margin: "85px 85px" }}>
        <ProductCards title="Featured products" />
      </div>
    </div>
  );
}
export default ProductPage;
