import axios from "axios";

import config from "../config";

const chainID = JSON.parse(localStorage.getItem("outletChainID"));

//console.log(config?.outletchainid_staging);
const customInstance = axios.create({

baseURL: config.baseURL,

  headers: { Accept: "application/json",
 
  consumerid:config?.consumerId,
  outletchainid: chainID ? chainID : config?.outletchainid_staging  ,

  // consumerid:1,
  // outletchainid: "2289c0cf-aa2b-49ec-356d-5215fb8bfb99",
},
});

export default customInstance;

  //baseURL: "https://cannaby-consum.herokuapp.com/",
  //baseURL: "https://cannaby-consumer.herokuapp.com/",
  //baseURL: "https://bleaum-consumer-staging.herokuapp.com/",
  //baseURL: "https://Graphql-cannaby-consumer.herokuapp.com/",
// baseURL: "https://graphql-cannaby-consumer.herokuapp.com/",

//For staging Hamlitons
//baseURL: "https://api-staging-alpha.bleaum.dev/",

//For prod Hamlitons
//baseURL: "https://api-alpha.bleaum.dev",

//For production Hamlitons
//baseURL: "https://hamiltons.bleaum.dev",

//For prod happydaze
//baseURL: "https://happydaze.bleaum.dev/",

//For  doobies
//baseURL: "https://doobies-stagging.herokuapp.com/",

//baseURL: "https://standalone-consumer.staging.bleaum.dev/",


 //baseURL: " https://happydazecannabis.herokuapp.com/",
// baseURL: " https://api-happy.bleaum.dev/",

/////////////////////////////////////cendientals////////////////////////////////////////////////////////
//for local production happy daze
// let Google = '88085308192-oos02jat2gkh7r1nghvruojgvq8o0i6n.apps.googleusercontent.com' ;
// let Facebook = "550542740008115" ;

//For production happy daze
// let Google = '88085308192-u328henlui6b7o9cu26cd5ghllfrvjlp.apps.googleusercontent.com' ;
// let Facebook = "550542740008115" ;


// For production Hamilton
//let Google = '88085308192-tsbejmp0t1p2u4p9t7c954odu3de0mtk.apps.googleusercontent.com' ;

//For local  hamilton
// let Google = '88085308192-rj28mv7iju47ii32uhsgdnd8obak50j9.apps.googleusercontent.com' ;
// let Facebook = "550542740008115" ;

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////