import { Tooltip } from "@nextui-org/react";
import React, { useEffect, useState, useContext } from "react";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { camelCase, startCase } from "lodash";

import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import greenPlaceholder from "../../images/Green_placeholder.png";
import { ChatContext } from "../../Contexts/ChatContext";

function ProductItem(props) {
  // console.log(props);
  const { setLoginModal, setFeaturedProductId } = useContext(ChatContext);

  const [fav, setFav] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  let loggedIn = localStorage.getItem("user") ? true : false;

  useEffect(() => {
    props.wish?.map((s) => {
      if (s.product.productID === props.productID) {
        setFav(true);
      }
      return "";
    });
    if (!props?.image) setIsLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checklogin = () => {
    if (!loggedIn) {
      setFav(false);
      return setLoginModal(true);
    } else {
      if (!fav) {
        const Toast = Swal.mixin({
          toast: true,
          position: "bottom-right",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "success",
          title: "Added to Wishlist",
        });
      }
    }
  };

  return (
    <Card
      className={
        props.discount > 35 ? "colorBackground phoneprod " : "phoneprod"
      }
      style={{
        margin: "20px",
        width: "270px",
        height: "401px",
        background: "#F7FAFC",
        borderRadius: "10px",
        fontFamily: "Oswald, sans-serif",
      }}>
      <div className="heartIcon-home">
        {!fav ? (
          <svg
            onClick={() => {
              props.addFav(!fav, props);
              setFav(!fav);
              checklogin();
            }}
            style={{ cursor: "pointer" }}
            width="23"
            height="21"
            viewBox="0 0 23 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              className="strokeColor"
              d="M17.1627 15.5153C14.5248 18.0699 11.8265 19.8822 11.6176 20.0212L11.5001 20.0994L11.3825 20.0211C11.3825 20.0211 11.3825 20.0211 11.3824 20.0211C11.1734 19.882 8.4751 18.0698 5.83737 15.5153C4.51824 14.2378 3.23391 12.7934 2.28372 11.2974C1.32885 9.7941 0.75 8.29926 0.75 6.90557C0.75 2.80021 3.56821 0.75 6.29798 0.75C8.05214 0.75 9.71865 1.58083 10.916 3.06159L11.4997 3.78336L12.0828 3.06116C13.2756 1.58378 14.9447 0.75 16.7023 0.75C19.432 0.75 22.25 2.80041 22.25 6.90557C22.25 8.29926 21.6711 9.7941 20.7163 11.2974C19.7661 12.7934 18.4818 14.2378 17.1627 15.5153Z"
              stroke="#08754C"
              stroke-width="1.5"
            />
          </svg>
        ) : (
          <svg
            onClick={() => {
              props.addFav(!fav, props);
              setFav(!fav);
              checklogin();
            }}
            style={{ cursor: "pointer" }}
            width="23"
            height="21"
            viewBox="0 0 23 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              className="svgColor"
              d="M11.5 21L10.9671 20.6456C10.5193 20.3477 0 13.2794 0 6.90557C0 2.37181 3.16832 0 6.29798 0C8.29864 0 10.1725 0.94935 11.4992 2.59001C12.8216 0.952214 14.6983 0 16.7023 0C19.8319 0 23 2.37207 23 6.90557C23 13.2794 12.4807 20.3477 12.0331 20.6456L11.5 21Z"
              fill="#08754C"
            />
          </svg>
        )}
      </div>

      <Link
        to={"/product/" + props.productID}
        className="productDiscountLink"
        style={{ textDecoration: "none" }}>
        {!props.discount ? null : (
          <p
            className={
              props.discount > 35 ? "discountPercent" : "discountPercent"
            }
            style={{
              backgroundColor: "#2151ED",
              marginLeft: "10px",
              marginBottom: "-11px",
              fontFamily: "Oswald, sans-serif",
              marginTop: "10px",
              height: "19px",
              color: "white",
              fontSize: "12px",
              width: "38px",
              textAlign: "center",
              borderRadius: "6px",
            }}>
            {props.discount}%
          </p>
        )}
      </Link>

      <Link
        to={"/product/" + props.productID}
        style={{ textDecoration: "none", alignSelf: "center" }}>
        {!props.image ? (
          <Card.Img
            className="loadingcardImg"
            variant="bottom"
            style={{
              height: "95px",
              width: "95px",
              marginTop: "20px",
              marginBottom: "17px",
            }}
            src={noimg}
          />
        ) : (
          <Card.Img
            className="cardImg"
            style={{ display: !isLoaded ? "none" : "flex", marginTop: "13px" }}
            src={props.image}
            onLoad={() => {
              setIsLoaded(true);
            }}
          />
        )}
        {!isLoaded ? (
          <Card.Img
            className="loadingcardImg"
            variant="bottom"
            style={{
              marginTop: "23px",
              width: "110px",
              height: "100px",
              alignSelf: "center",
            }}
            src={greenPlaceholder}
          />
        ) : (
          ""
        )}
      </Link>

      <Card.Body className="card-body">
        <div className="lower-card">
          <div style={{ marginTop: "15px", textAlignLast: "center" }}>
            <span
              className={
                props.discount > 35 ? "textColorDis priceCard" : "priceCard"
              }>
              <span
                className={
                  props.discount > 35 ? "textColor dollar" : "dollar"
                }></span>{" "}
              {props?.vPrice
                ? props.currency + " " + parseFloat(props?.vPrice).toFixed(2)
                : "N/A"}
            </span>
          </div>
        </div>

        {props?.name?.length <= 20 ? (
          <Link
            to={"/product/" + props.productID}
            style={{ textDecoration: "none" }}>
            {props.discount > 35 ? (
              <Card.Text
                className={
                  props.discount > 35
                    ? "secondColor HCardNameDic"
                    : "textColorDis HCardName"
                }>
                {startCase(camelCase(props?.name))}
              </Card.Text>
            ) : (
              <Card.Text
                className={
                  props?.name?.length <= 20 ? "HCardNameC" : "HCardName"
                }>
                {startCase(camelCase(props?.name))}
              </Card.Text>
            )}
          </Link>
        ) : (
          <Tooltip
            content={props?.name}
            color="success"
            rounded={false}
            placement={"top"}>
            <Link
              to={"/product/" + props.productID}
              style={{ textDecoration: "none" }}>
              <Card.Text
                className={
                  props.discount > 35 ? "secondColor HCardNameD" : "HCardNameD"
                }>
                {startCase(camelCase(props?.name?.substring(0, 20)))}...
              </Card.Text>
            </Link>
          </Tooltip>
        )}

        {props?.brandName?.length <= 15 ? (
          <span className="p-card-brand">
            {props?.brandName ? props?.brandName : "N/A"}
          </span>
        ) : (
          <span className="p-card-brand">
            {props?.brandName
              ? props?.brandName.substring(0, 15) + "..."
              : "N/A"}
          </span>
        )}

        <span className="p-card-thc">
          THC: {props?.thc ? props?.thc + "%" : "N/A"}
        </span>

        <div className="lower-card">
          <Link
            to={"/product/" + props.productID}
            style={{ textDecoration: "none" }}>
            <div
              className="veiw-product-btn-home cursor"
              onClick={() => {
                setFeaturedProductId("");
              }}>
              View Product
            </div>
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
}

export default ProductItem;
