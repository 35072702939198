import { camelCase, startCase } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Input from "react-phone-number-input/input";
//import item from "../../images/item.svg";
import url from "../../config/axios";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";

function OrderDetails() {
  const params = useParams();
  const user = JSON.parse(localStorage.getItem("user"));

  const [order, setOrder] = useState([]);
  const [products, setProducts] = useState([]);
  let orderid = params.orderid;

  const history = useHistory();

  //console.log(order);

  useEffect(() => {
    getOrder(orderid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOrder = (id) => {
    url
      .get(`/order/?orderID=${id}`)
      .then((res) => {
        setOrder(res.data.data);
        setProducts(res.data.data[0].products);
      })
      .catch((error) => {});
  };

  const redirect = (link) => {
    history.push("/product/" + link);
    window.location.reload(false);
  };

  let curr = order[0]?.products[0]?.selectedVariantData?.pricingInfo?.currency
    ? order[0]?.products[0]?.selectedVariantData?.pricingInfo?.currency ===
      "USD"
      ? " $ "
      : order[0]?.products[0]?.selectedVariantData.pricingInfo?.currency + " $ "
    : " $ ";

  return (
    <>
      <div className="order-details">
        <div className="d-flex justify-content-between phoneOrderD">
          <section>
            <div className="details-card h-100">
              <div className="order-upper ">
                <div>
                  <span className="order-label">Order ID:</span>
                  <span className="order-id ">
                    {" "}
                    {params.orderid.toUpperCase()}
                  </span>
                  <div>
                    <span className="order-label">Placed On:</span>
                    <span className="order-labelValue">
                      {" "}
                      {moment(order[0]?.createdAt).format("LLLL")}
                    </span>
                  </div>
                  <span>
                    <div>
                      <span className="order-label"> Outlet Address :</span>

                      <span className="order-labelValue">
                        {" "}
                        {order[0]?.products[0]?.outletAddress}
                      </span>
                    </div>
                  </span>
                </div>
              </div>
              {/* <div className="mt-3 d-flex align-items-center status">
                <span className="dot me-2" style={{ backgroundColor: "#45F256" }}></span>
                <p style={{marginTop: "17px"}}>Delivered on 23 Oct. 2021</p>
              </div> */}
              <hr />
              {products &&
                products.map((o, i) => (
                  <>
                    <div
                      className="order-item w-100 d-flex justify-content-between  "
                      key={i}>
                      {!o.image ? (
                        <img
                          className="cursor orderDnoImg"
                          onClick={() => {
                            redirect(o.productID);
                          }}
                          src={noimg}
                          alt=""
                        />
                      ) : (
                        <img
                          className="cursor"
                          onClick={() => {
                            redirect(o.productID);
                          }}
                          src={o.image}
                          alt=""
                        />
                      )}

                      <div
                        className="row"
                        style={{ marginLeft: "20px", width: "100%" }}>
                        <div className="col-8 mobile-orderDetial-pName">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                            }}>
                            <span
                              className="cursor orderDetials-prodName"
                              onClick={() => {
                                redirect(o.productID);
                              }}>
                              {startCase(camelCase(o.name))}
                            </span>
                            {o.selectedVariantData?.weight ? (
                              <span
                                className="cartVarient"
                                style={{ color: "#121212" }}>
                                {o.selectedVariantData?.weight}{" "}
                                {o.selectedVariantData?.uom}
                              </span>
                            ) : null}
                          </div>
                        </div>

                        <div className="col">
                          <span className="orderDetials-prodPrice ">
                            {o.purchaseQuantity} x{" "}
                            {o.selectedVariantData?.pricingInfo?.currency
                              ? o.selectedVariantData?.pricingInfo?.currency ===
                                "USD"
                                ? "$"
                                : o.selectedVariantData?.pricingInfo?.currency +
                                  "$"
                              : "$"}
                            {o.discountPrice ? o.discountPrice : o.price}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div
                      className=" mobile-h"
                      style={{ display: "flex", justifyContent: "flex-end" }}>
                      <hr className="w-75 mobile-order-hr " />
                    </div>
                  </>
                ))}

              <div>
                {order[0]?.couponDiscount === "N/A" ||
                !order[0]?.couponDiscount ||
                order[0]?.couponDiscount === "0" ? null : (
                  <>
                    <div className="order-price w-100 d-flex justify-content-end">
                      <div
                        className="d-flex  justify-content-between pe-1"
                        style={{ width: "72%" }}>
                        <span className="text-end bill-title-discount">
                          Coupon Discount
                        </span>
                        <span className="bill-title-discount">
                          {order[0]?.couponDiscount === "undefined%"
                            ? "N/A"
                            : "- " +
                              order[0]?.products[0]?.selectedVariantData
                                ?.pricingInfo?.currency
                            ? order[0]?.products[0]?.selectedVariantData
                                ?.pricingInfo?.currency === "USD"
                              ? "- $"
                              : "- $ "
                            : "- $"}
                          {parseFloat(order[0]?.couponDiscount)?.toFixed(2)}
                        </span>
                      </div>
                    </div>
                    {/* <div className="d-flex justify-content-end mobile-h">
                    <hr className="w-75 mobile-order-hr" />
                  </div> */}
                  </>
                )}

                {order[0]?.products[0].LoyaltyPointsDiscount ? (
                  <div
                    className="order-price w-100 d-flex justify-content-end"
                    style={{ marginTop: "20px" }}>
                    <div
                      className="d-flex  justify-content-between pe-1"
                      style={{ width: "72%" }}>
                      <span className="text-end  bill-title-discount">
                        Loyalty Points Discount
                      </span>
                      <span className="bill-title-discount">
                        {order[0]?.products[0].LoyaltyPointsDiscount
                          ? "- $ " +
                            parseFloat(
                              order[0]?.products[0].LoyaltyPointsDiscount
                            )?.toFixed(2)
                          : "N/A"}
                      </span>
                    </div>
                  </div>
                ) : null}

                {order[0]?.products[0].LoyaltyPointsDiscount ||
                order[0]?.couponDiscount !== "0" ? (
                  <div className="d-flex justify-content-end mobile-h">
                    <hr className="w-75 mobile-order-hr" />
                  </div>
                ) : null}
              </div>
              <div className="order-price w-100 d-flex justify-content-end">
                <div
                  className="d-flex  justify-content-between pe-1"
                  style={{ width: "72%" }}>
                  <span className="text-end order-label">
                    Subtotal ({order[0]?.products?.length}{" "}
                    {order[0]?.products?.length < 1 ? "items" : "item"} )
                  </span>
                  <span className="order-labelValue">
                    {order[0]?.products[0]?.selectedVariantData?.pricingInfo
                      ?.currency
                      ? order[0]?.products[0]?.selectedVariantData?.pricingInfo
                          ?.currency === "USD"
                        ? "$"
                        : order[0]?.products[0]?.selectedVariantData
                            ?.pricingInfo?.currency + "$"
                      : "$"}{" "}
                    {order[0]?.totalPrice.toFixed(2)}
                  </span>
                </div>
              </div>
              {/* <div className="d-flex justify-content-end mobile-h">
                <hr className="w-75 mobile-order-hr " />
              </div> */}

              {/* <div
                className="order-price w-100 d-flex justify-content-end"
                style={{ marginTop: "20px" }}>
                <div
                  className="d-flex  justify-content-between pe-1"
                  style={{ width: "72%" }}>
                  <span className="text-end order-label">
                    Standard Tax (
                    {order[0]?.products[0]?.outletsTax?.length !== 0
                      ? order[0]?.products[0]?.outletsTax + "%"
                      : 0 + "%"}
                    )
                  </span>
                  <span className="order-labelValue">
                    {order[0]?.products[0]?.tax
                      ? " $ " +
                        parseFloat(order[0]?.products[0]?.tax)?.toFixed(2)
                      : "N/A"}
                  </span>
                </div>
              </div> */}

              {order[0]?.additionalCharges?.map((t, i) => {
                let lpDis = order[0]?.LoyaltyPointsDiscount;
                let coupDis = order[0]?.couponDiscount;
                let totalPrice =
                  (order[0]?.totalPrice - lpDis - coupDis) * t?.amount;

                //  console.log(totalPrice);
                return (
                  <div
                    className="order-price w-100 d-flex justify-content-end"
                    style={{ marginTop: "20px" }}>
                    <div
                      className="d-flex  justify-content-between pe-1"
                      style={{ width: "72%" }}>
                      <span className="text-end order-label">
                        {t?.name}({t?.amount}
                        {t?.chargeType === "PERCENTAGE" ? "%" : curr})
                      </span>
                      <span className="order-labelValue">
                        {t?.chargeType === "PERCENTAGE"
                          ? curr +
                            parseFloat(
                              totalPrice / 100 < 0 ? 0 : totalPrice / 100
                            )?.toFixed(2)
                          : curr + parseFloat(t?.amount)?.toFixed(2)}
                      </span>
                    </div>
                  </div>
                );
              })}

              <div className="d-flex justify-content-end mobile-h">
                <hr className="w-75 mobile-order-hr" />
              </div>

              <div className="order-price w-100 d-flex justify-content-end">
                <div
                  className="d-flex  justify-content-between pe-1"
                  style={{ width: "72%" }}>
                  <span className="orderDetials-prodName">Total</span>
                  <span className="orderDetials-prodPrice">
                    {order[0]?.products[0]?.selectedVariantData?.pricingInfo
                      ?.currency
                      ? order[0]?.products[0]?.selectedVariantData?.pricingInfo
                          ?.currency === "USD"
                        ? "$"
                        : order[0]?.products[0]?.selectedVariantData.pricingInfo
                            ?.currency + "$"
                      : "$"}{" "}
                    {parseFloat(order[0]?.finalPrice)?.toFixed(2)}
                  </span>
                </div>
              </div>
            </div>
          </section>
          <section
            className="phone-right-orderDetials"
            style={{ wordWrap: "break-word", marginLeft: "20px" }}>
            <div className="add-card " style={{ wordWrap: "break-word" }}>
              <span className="rderDetials-prodName">Billing Address</span>
              <hr />
              <h6 className="mt-3">{order[0]?.customer?.Address?.name}</h6>
              <div>
                <p>{order[0]?.customer?.Address?.address}</p>
              </div>

              <span className="order-label">Outlet name: </span>
              <br />
              <span className="add-card-labelValue">
                {" "}
                {order[0]?.customer?.outletName
                  ? order[0]?.customer?.outletName
                  : "N/A"}
              </span>
              <br />
              <span className="order-label">Customer Name: </span>
              <br />
              <span className="add-card-labelValue">
                {" "}
                {order[0]?.customer?.name ? order[0]?.customer?.name : "N/A"}
              </span>
              <br />
              <span className="order-label">Customer Email: </span>
              <br />
              <span className="add-card-labelValue">
                {" "}
                {order[0]?.customer?.email ? order[0]?.customer?.email : "N/A"}
              </span>
              <br />
              <span className="order-label" style={{ marginTop: "25px" }}>
                Phone Number:{" "}
              </span>
              <br />
              <span className="add-card-labelValue">
                {" "}
                <Input
                  className="add-card-labelValue"
                  style={{
                    width: "100%",
                    padding: "0px",
                    border: "none",
                    fontWeight: "500",
                    color: "#00000",
                    fontSize: "18px",
                  }}
                  disabled
                  value={user[0]?.mobileNo ? user[0]?.mobileNo : "N/A"}
                />
                {}
              </span>
              <br />
            </div>
          </section>
        </div>
      </div>
    </>
  );
}

export default OrderDetails;
