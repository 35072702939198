import DealsCard from "../../components/Deals/DealsCard";
import TopHeader from "../../components/TopHeader/TopHeader";

function Deals() {
  return (
    <>
     <TopHeader pageName={"Deals" } />
      <div className="deals">
        <DealsCard />
       
      </div>
      
    </>
  );
}

export default Deals;
