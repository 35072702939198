import NotificationCard from "../../components/Notifications/NotificationCard";
import "./notifications.css";
import TopHeader from "../../components/TopHeader/TopHeader";

function Notifications() {
  return (
    <>
      <TopHeader pageName={"Notifications"} />

      <div className="notification-dev">
        <NotificationCard />
      </div>
    </>
  );
}

export default Notifications;
