import React from "react";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";

import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";

export default function SkeletionLoadingCart(props) {
  return (
    <>
      <Card className="filterSkelCard">
        {!props.img ? (
          <Card.Img
            variant="bottom"
            style={{
              height: "88px",
              weight: "95px",
              width: "95px",
              alignSelf: "center",
            }}
            src={noimg}
          />
        ) : (
          <Card.Img className="cardImg" src={props.img} />
        )}

        <Card.Body
          style={{
            width: "100%",
            textAlign: "center",
            justifyContent: "center",
          }}>
          <div
            className="lower-card"
            style={{ width: "100%", display: "block" }}>
            <div className="row">
              <div className="col-8">
                <div style={{ textAlign: "start" }}>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={12} style={{ borderRadius: "10px" }} />
                  </Placeholder>
                </div>

                <div style={{ textAlign: "start" }}>
                  <Placeholder as={Card.Title} animation="glow">
                    <Placeholder xs={6} style={{ borderRadius: "10px" }} />
                  </Placeholder>
                </div>
              </div>

              <div className="col" style={{ alignSelf: "center" }}>
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={12} style={{ borderRadius: "10px" }} />
                </Placeholder>
              </div>

              <div className="col" style={{ alignSelf: "center" }}>
                <Placeholder as={Card.Title} animation="glow">
                  <Placeholder xs={12} style={{ borderRadius: "10px" }} />
                </Placeholder>
              </div>
            </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
}
