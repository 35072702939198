import React, { useState } from "react";

import url from "../../config/axios";
import logo from "../../images/logo.svg";

//import { useHistory } from "react-router-dom";
import "./Login.css";

function LoginModalW({ help, loginM }) {
  // let history = useHistory();
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [first, setFirst] = useState(true);
  const [second, setSecond] = useState(false);

  const linkAcc = () => {
    url
      .get(`/user/login/?email=${email}&password=${pass}`)
      .then((res) => {
        if (res.data.Message === "Success") {
          // localStorage.setItem("user", JSON.stringify(res.data.data));
          loginM(false);
          // window.location.reload();
        } else {
          // alert("Not Login", res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  const reset = () => {
    setFirst(false);
    setSecond(true);
  };

  const back = () => {
    setSecond(false);
    setFirst(true);
  };

  const helpM = () => {
    help(true);
    loginM(false);
  };

  return (
    <div className="modals">
      <div className="overlay"></div>
      <div className="login-modal">
        <div className="topmodal">
          <img src={logo} alt="logo" />
          {second && (
            <i className="fas fa-chevron-left" onClick={() => back()}></i>
          )}
          {first && <i className="fas fa-x" onClick={() => loginM(false)}></i>}
        </div>
        {first && (
          <>
            <span>Link Account & Login</span>
            <div className="input-login2">
              <p>Email</p>
              <input
                type="email"
                placeholder="xyz@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="input-login">
              <input
                type="password"
                className="pass-input"
                placeholder="Password"
                onChange={(e) => setPass(e.target.value)}
              />
            </div>
            <button className="gradBackground" onClick={() => linkAcc()}>
              LOGIN
            </button>
            <span>
              Forgot your password?{" "}
              <mark onClick={() => reset()}>Reset here</mark>
            </span>
          </>
        )}
        {second && (
          <>
            <span>Reset Password</span>
            <div className="input-login2">
              <p>Email</p>
              <input
                type="email"
                placeholder="xyz@gmail.com"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <button className="gradBackground">SEND LINK</button>
          </>
        )}
        <span>
          Having trouble logging in?{" "}
          <mark onClick={() => helpM()}>Get help</mark>
        </span>
      </div>
    </div>
  );
}

export default LoginModalW;
