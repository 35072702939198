import { startCase } from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import url from "../../config/axios";
import editIcon from "../../Icons/edit.svg";

function AddressList() {
  const [addresses, setAddresses] = useState([]);



  let user = JSON.parse(localStorage.getItem("user"));
  let uid = user[0]?.userID;
  useEffect(() => {
    getAllAddress(uid);
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAllAddress = (id) => {

    url.get(`/user/address?userID=${id}`).then(async (res) => {
      if (res.data.Message === "Success") {

        //let data = res.data.data;

        setAddresses(res.data.data.addresses);
      } else {

      }
    });
  };

  const deleteAddress = (id) => {
    url.delete(`/user/address?userID=${uid}&addressID=${id}`).then(async (res) => {
      if (res.data.Message === "Success") {
        getAllAddress(uid);
      } else {

      }
    });
  };

  return (
    <>
      <div className="dashboard">
        <span>My Addresses</span>
        <div className="all-address flex-wrap">
          <Link to="/profile/add-address" style={{ textDecoration: "none" }}>
            <div className="address-card">
              <i className="fas fa-plus" />
              <span>Add Address</span>
            </div>
          </Link>

          {addresses &&
            addresses.map((add, i) => (
              <div className="addresses-card" key={i}>
                <div className="add-card-top">
                  <span>{startCase(add.type)}</span>
                  {add.default && <span>Default Address</span>}
                  <div className="div">
                    <img src={editIcon} alt="editicon" />
                  </div>
                </div>
                <div className="address-body">
                  <span>{add.name}</span>
                  <p>
                    {add.address}, {add.city}, {add.state} {add.zipcode}, {add.country}
                  </p>
                  <p>Phone Number: {add.contact}</p>
                </div>
                <span>Open on Saturdays and Sundays</span>
                <span onClick={() => deleteAddress(add.addressID)}>DELETE</span>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}

export default AddressList;
