import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import url from "../../config/axios";
import { ChatContext } from "../../Contexts/ChatContext";
import TopHeader from "../TopHeader/TopHeader";
import EmptiedWishlist from "./EmptiedWishlist";
import FWishlist from "./FWishlist";

import "./Wishlist.css";

function Wishlist() {
  const { setWishCount, cartCount, setCartCount } = useContext(ChatContext);

  // const [wishCount, setWishCount] = useState(0);
  const [item, setItem] = useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  // const uid = JSON.parse(localStorage.getItem("uid"));
  let loggedIn = localStorage.getItem("user") ? true : false;
  const history = useHistory();

  useEffect(() => {
    getWishItems();

    if (!loggedIn) {
      // window.location.reload(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getWishItems = () => {
    if (loggedIn) {
      url
        .get(`/wishlist/?userID=${user[0]?.userID}`)
        .then((res) => {
          if (res.data.Message === "Success") {
            if (res?.data?.data?.length < 1) setItem([]);
            setItem(res?.data?.data);
            setWishCount(res?.data?.data?.length);
          }
          // setWishCount(item?.length);
        })
        .catch((e) => console.log(e));
    }
  };
  //  getWishItems()

  const deleteAll = () => {
    url
      .delete(`/wishlist/all/?userID=${user[0].userID}`)
      .then((res) => {
        setItem([]);
        setWishCount(0);
      })
      .catch((e) => console.log(e));
  };

  const deleteWishlist = (id, pid) => {
    let x = item.filter((e) => {
      return e._id !== pid;
    });

    setItem(x);
    url
      .delete(`/wishlist/?wishlistID=${id}`)
      .then((res) => {
        if (res.data.Message === "Success") {
          getWishItems();
          setCartCount(cartCount + 1);
        } else {
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <TopHeader pageName={"Wishlist"} />
      <div className="container" style={{ paddingTop: "10px" }}>
        <div
          className="upper-title d-flex justify-content-between"
          style={{ flexDirection: "row-reverse" }}>
          {/* <p>
          My Wishlist <mark>{item?.length}</mark><mark>{item?.length <= 1 ? "Item" : "Items"}</mark>
        </p> */}

          {item?.length === 0 ? null : (
            <div
              style={{ background: "white" }}
              className="img d-flex justify-content-center align-items-center"
              onClick={() => deleteAll()}>
              {/* <img src={Bin} alt="delicon"/> */}
              <svg
                width="18"
                height="22"
                viewBox="0 0 18 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  className="svgColor"
                  d="M13.6663 7.5V19.1667H4.33301V7.5H13.6663ZM11.9163 0.5H6.08301L4.91634 1.66667H0.833008V4H17.1663V1.66667H13.083L11.9163 0.5ZM15.9997 5.16667H1.99967V19.1667C1.99967 20.45 3.04967 21.5 4.33301 21.5H13.6663C14.9497 21.5 15.9997 20.45 15.9997 19.1667V5.16667Z"
                  fill="#1F6355"
                />
              </svg>
            </div>
          )}
        </div>
        {/* <WishCounter item={item}/> */}
        {item?.length === 0 ? (
          <EmptiedWishlist message={"Wishlist"} />
        ) : (
          <FWishlist
            itemt={item}
            getWishItems={getWishItems}
            deleteWishlist={deleteWishlist}
          />
        )}
        {/* <WishCounter item={item}/> */}
      </div>
    </>
  );
}

export default Wishlist;
