import React from 'react'
import Card from 'react-bootstrap/Card'
import Placeholder from 'react-bootstrap/Placeholder'

import noimg from '../../images/Bloom Icon (Filled) 1 (1).png'

export default function SkeletionCategory() {
  return (
    <div>
      <Card className="phoneSkelLoading" style={{ paddingBottom: "13px", width: "270px", height: "231px", marginRight: "20px" }} >

        <Card.Body style={{ marginLeft: "15px" }}>

          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} style={{ borderRadius: "10px" }} />
          </Placeholder>

          <Card.Text className="textColor" style={{ fontSize: "20px", fontWeight: "400", lineHeight: "30px" }}></Card.Text>

          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={6} style={{ borderRadius: "10px" }} />
          </Placeholder>
        </Card.Body>
        <Card.Img variant="bottom" style={{ width: "100px", marginBottom: "12%", alignSelf: "center" }} src={noimg} />


      </Card>
    </div>
  )
}
