import Dashboard from "./Dashboard";

function ProfileMain() {
  return (
    <div className="main-profile">
      <Dashboard />
    </div>
  );
}

export default ProfileMain;
