import React from 'react'
import coverImgleft from '../../images/Cannabis-leaf-left-PNG.png'
import coverImgRight from '../../images/Cannabis-leaf-right-PNG.png'

export default function TopHeader(props) {
  return (
    <div className='coverBanner' >
      <div className="row" style={{width:"100%",display:"flex" ,justifyContent:"space-between"}}>
        <div className="col-2" style={{padding:"0px"}}>
            <img src={coverImgRight} alt='coverImg' style={{ width: "197px",height: "187px" }} />
        </div>

        <div className="col" style={{display: "flex",justifyContent: "center",alignItems: "center"}}>
              <div className='shopCover'>
            <p>{props?.pageName } </p>
            <span> Home  /  {props.pageName} {props?.total ?<> ({props?.total}) </>: null} {props?.subPageName ? props?.subPageName : null}</span>
          </div>
        </div>

        <div className="col-2" style={{padding:"0px",display:"flex",justifyContent:"flex-end"}}>
           <img src={ coverImgleft} alt='coverImg' style={{ width: "197px",height: "187px"}} />
        </div>
      </div>
    
    
  </div>
  )
}
