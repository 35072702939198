import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import AllCategoriesCard from "../../components/Categories/AllCategoriesCard";
import SkeletionCategory from "../../components/Categories/SkeletionCategory";
import TopHeader from "../../components/TopHeader/TopHeader";
import url from "../../config/axios";

function Allcategories() {
  const [category, setCategory] = useState([]);
  const [skel, setskel] = useState(true)

  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  const history = useHistory();

  useEffect(() => {
    url.get(`/category/outlet?OutletId=${chainID}`).then(async (res) => {

      if (res.data.Message === "Success") {
        setskel(false)
        setCategory(res.data.data);
      } else {
        // alert(res.data.data);
      }
    });
  }, [chainID]);

 


  return (
    <>
    <TopHeader pageName={"Categories" } total={category.length}/>
      <div className="container" style={{ maxWidth: "100%", paddingBottom: "102px" }}>
        {/* <h5 className="gray-title breadcrumbsDef"><span className="breadcrumbsDef" style={{ cursor: "pointer" }} onClick={() => history.push(`/`)}> Home</span> &nbsp;&gt;&nbsp; All Categories ({category.length})</h5> */}


        <div className="all-cats " >
          {skel ? <>  <SkeletionCategory /> <SkeletionCategory /> <SkeletionCategory /> <SkeletionCategory /> </> :
            <>
              {

                category.map((cat, i) => (
                  <AllCategoriesCard productCount={cat?.countInventory[0]?.count} name={cat?.name} image={cat?.image} key={i} categoryID={cat?.categoryID} />

                ))}
            </>}
        </div>
      </div>
    </>
  );
}

export default Allcategories;
