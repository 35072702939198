import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";

import url from "../../config/axios";

export default function Loyalpoints(props) {
  const [redeemPoints, setRedeemPoints] = useState("");
  const [loyalVal, setLoyalVal] = useState(false);
  const [loyalValTotal, setLoyalValTotal] = useState(false);
  const [totalLoyalty, setTotalLoyalty] = useState(false);

  //console.log(props);

  //console.log( props?.loyaltyPoints?.points  );
  useEffect(() => {
    if (!props?.loyaltyPoints?.points) setLoyalValTotal(true);
    else setLoyalValTotal(false);
    //  if(  !props.pointsToPrice     )  return setLoyalValTotal(true)
    //else {setLoyalValTotal(false)}
  }, [props.pointsToPrice, props?.loyaltyPoints?.points, redeemPoints]);

  const radeemVAl = (r) => {
    if (!redeemPoints)
      if (props.Final < r * props.pointsToPrice) {
        //console.log(props?.loyaltyPoints?.points );
        //console.log(loyalValTotal);
        //console.log(r);
        return setLoyalVal(true);
      } else {
        setLoyalVal(false);
      }

    if (props?.loyaltyPoints?.points < r) {
      return setTotalLoyalty(true);
    } else {
      setTotalLoyalty(false);
    }
  };

  let halfSubtotal =
    props.price - (props.price / 100) * +props?.charges?.percentageThreshold;
  let totalAmountThreshold = +props?.charges?.amountThreshold;
  const redeem = redeemPoints * props.pointsToPrice;
  let lPointsValue = (props?.pointsToPrice * redeemPoints)?.toFixed(2);

  const RedeemRequired = () => {
    if (!redeemPoints)
      return Swal.fire("warning ", "Redeem points is Required", "warning");

    // console.log(redeem);
    // console.log(halfSubtotal);
    // console.log(totalAmountThreshold);
    if (redeem > totalAmountThreshold && halfSubtotal) {
      props.setRedeem(0);
      setRedeemPoints("");
      return Swal.fire(
        "Oops! Looks like there was an error with your loyalty points redemption ",
        "Please enter a value less than or equal to " +
          "$" +
          lPointsValue +
          " (" +
          redeemPoints +
          " Points" +
          ")" +
          " and ensure that total amount is at least " +
          "$" +
          parseFloat(props?.price - props?.couponDiscount)?.toFixed(2) +
          ", if you want to redeem " +
          redeemPoints +
          " points. Thank you!",
        "warning"
      );
      // const Toast = Swal.mixin({
      //   toast: true,
      //   position: "bottom-right",
      //   showConfirmButton: false,
      //   timer: 3000,
      //   timerProgressBar: true,
      //   didOpen: (toast) => {
      //     toast.addEventListener("mouseenter", Swal.stopTimer);
      //     toast.addEventListener("mouseleave", Swal.resumeTimer);
      //   },
      // });

      // Toast.fire({
      //   icon: "warning",
      //   title:
      //     // "Order should be Greater than " +
      //     // props?.charges?.percentageThreshold +
      //     // "% off and up to " +
      //     // props?.charges?.amountThreshold +
      //     // "$ total Price",
      //     // parseFloat(props.pointsToPrice * redeemPoints).toFixed(2)(
      //     //   redeemPoints
      //     // ) +
      //     // "," +
      //     // "this calculation will be done based on the % of subtotal and the" +
      //     // parseFloat(props.price).toFixed(2) +
      //     // "is the maximum threshold",
      // });
    }
  };
  // console.log(redeemPoints);
  const tosterRedeem = () => {
    if (redeem > totalAmountThreshold && halfSubtotal) return;
    setRedeemPoints("");
    const Toast = Swal.mixin({
      toast: true,
      position: "bottom-right",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });

    Toast.fire({
      icon: "success",
      title: "Loyalty Points Redeemed ",
    });
  };

  const handleChange = (e) => {
    const regex = /^[0-9]*$/;

    if (regex.test(e)) {
      setRedeemPoints(e);
      radeemVAl(e);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === ".") {
      event.preventDefault();
    }
  };

  return (
    <div>
      <div className="loyalpoints">
        <span>
          You have{" "}
          {props?.loyaltyPoints?.points ? props?.loyaltyPoints?.points : 0}{" "}
          points worth $
          {(props.pointsToPrice * props?.loyaltyPoints?.points
            ? props.pointsToPrice * props?.loyaltyPoints?.points
            : 0
          ).toFixed(2)}{" "}
        </span>
        <div className="points-input">
          {!props?.loyaltyPoints?.points ? (
            <input disabled type="number" placeholder="Enter Loyalty Points" />
          ) : (
            <input
              type="number"
              placeholder="Enter Loyalty Points"
              value={redeemPoints}
              onKeyPress={handleKeyPress}
              onChange={(e) => {
                handleChange(e.target.value);
              }}
            />
          )}
          {loyalVal || loyalValTotal || totalLoyalty ? (
            <button disabled style={{ background: "gray" }}>
              REDEEM
            </button>
          ) : (
            <button
              onClick={() => {
                props.setRedeem(redeemPoints * props.pointsToPrice);
                props.setShowloyalDiscount(true);
                props.setRedeempoints(redeemPoints);
                setRedeemPoints(redeemPoints);
                tosterRedeem();
                RedeemRequired();
              }}>
              REDEEM
            </button>
          )}
        </div>
        {loyalVal ? (
          <span style={{ color: "red" }}>
            Total should be more than the discounted amount <br /> (1 Loyalty
            Point = ${parseFloat(props.pointsToPrice).toFixed(2)}).
            {/* You can only Redeem Loyalty points for the order which is greater
            than ${parseFloat(props.pointsToPrice).toFixed(2)} . */}
          </span>
        ) : null}
        {loyalValTotal ? (
          <span style={{ color: "red" }}>
            {" "}
            You dont have enough Loyalty points to Redeem.{" "}
          </span>
        ) : null}
      </div>
    </div>
  );
}
