import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import Spinner from "react-bootstrap/Spinner";
import url from "../../config/axios";

import "./banner.css";

function Banner() {
  const [isLoaded, setIsLoaded] = useState(false);
  const [banner, setBanner] = useState([]);
  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  // console.log(isLoaded);

  useEffect(() => {
    url.get(`/banner/?outletChainID=${chainID}`).then(async (res) => {
      if (res.data.Message === "Success") {
        setBanner(res.data.data);
        res.data.data.map((e) => {
          if (!e?.image) setIsLoaded(true);
        });
      } else {
      }
    });
  }, [chainID]);

  return (
    <>
      <Carousel
        style={{ width: "100%", borderRadius: "5px", zIndex: "1" }}
        variant="dark"
        controls={false}
        className="mx-auto "
        indicators={false}>
        {banner.map((e) => {
          return (
            <Carousel.Item style={{ height: "628px" }}>
              {e?.targetInfo?.type ? (
                <>
                  <Link
                    to={
                      e?.targetInfo?.type === "PRODUCTS"
                        ? "/product/" + e?.targetInfo?.productID
                        : e.targetInfo.type === "CATEGORIES"
                        ? "/products/category/" + e.targetInfo.categoryID
                        : e.targetInfo.type === "MANUFACTURERS"
                        ? "/products/manufacturers/" + e.targetInfo.brandID
                        : e.targetInfo.type === "DEALS"
                        ? "/products/Deals/" + e.targetInfo._id
                        : null
                    }
                    style={{ textDecoration: "none", height: "15px" }}>
                    <img
                      className="d-block w-100"
                      style={{
                        display: !isLoaded ? "none" : "flex",
                        position: " absolute",
                      }}
                      src={e.image}
                      alt=""
                      onLoad={() => {
                        setIsLoaded(true);
                      }}
                    />
                  </Link>
                  {!isLoaded ? (
                    <div className="spinner">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <>
                  <img
                    className="d-block w-100"
                    style={{
                      display: !isLoaded ? "none" : "flex",
                      position: " absolute",
                    }}
                    src={e.image}
                    alt=""
                    onLoad={() => {
                      setIsLoaded(true);
                    }}
                  />
                  {!isLoaded ? (
                    <div className="spinner">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : null}
                </>
              )}
            </Carousel.Item>
          );
        })}
      </Carousel>
    </>
  );
}

// style={{ backgroundImage: `url(${e.image})`, objectFit: "contain" }}

export default Banner;
