import { useParams } from "react-router-dom";

import ProductPage from "../../components/Products/ProductPage";
import TopHeader from "../../components/TopHeader/TopHeader";
import { ChatContext } from "../../Contexts/ChatContext";
import React, { useContext } from "react";

function SingleProducts() {
  const { featuredProductId } = useContext(ChatContext);

  const params = useParams();
  // console.log(params.prodName);
  return (
    <>
      <TopHeader pageName={"Product"} />
      <div className="single-prd">
        <ProductPage
          productpage={featuredProductId ? featuredProductId : params.prodName}
        />
      </div>
    </>
  );
}

export default SingleProducts;
