import cfg from "./bleaum.json";// <--- Add Configurations In This File.



let envVendor = process.env.REACT_APP_VENDOR;
let envStage = process.env.REACT_APP_STAGE;
let active = envVendor  || cfg.defaultVendor; //currently Active vendor
let api = envStage  ||  cfg.defaultAPI; // To Switch Between Production and Staging API
let obj = cfg[active]
console.log(`Vendor : ${active}`);
console.log(`Stage : ${api}`);
if(api === "production")
    obj.baseURL = obj.productionAPI;
else 
    obj.baseURL = obj.stagingAPI;
obj.key = active;
export default obj;
