import config from "../../config";

let logo = require("../../images/" + config.logo)

function HelpModal({ helpDis ,setProfile}) {

  return (
    <div className="modals" style={{ background: "rgba(49, 49, 49, 0.8)" }}>
      <div className="overlay" style={{top: "5%", borderRadius: "9px", background: "#E5E5E5", width: "457px", height: "430px", marginLeft: "31%", marginTop: "10%" }}>
        <div className="reset-contact">
          <div className="res-head">
            <img style={{ marginTop: " 3%", marginLeft: "11%", width: "15%" }} src={logo} alt="logo" />
            <i className="fas fa-times cursor cross" onClick={() =>{ helpDis(false);setProfile(false);}}></i>
          </div>

          <div className="res-body2">
            <p className="helpTxt">Contact us and we will get back to you shortly</p>
            <div>
              <p style={{ width: "80%", marginLeft: "10%", height: "92px", background: "#F3FFF8", border: "1px solid #B3FFD4", borderRadius: "9px", textAlign: "center", paddingTop: " 24px" }}>
                <span className="helpEmail"> {config.Email}</span> </p>
            </div>
            <div>
              <p style={{ width: "80%", marginLeft: "10%", height: "92px", background: "#F3FFF8", border: "1px solid #B3FFD4", borderRadius: "9px", textAlign: "center", paddingTop: " 24px" }}> <span className="helpEmail">{config.Contact_number} </span></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HelpModal;
