import moment from "moment";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import url from "../../config/axios";
import EmptiedWishlist from "./EmptiedWishlist";
import ItemCard from "./ItemCard";
import "./Order.css";

function Orders() {
  const user = JSON.parse(localStorage.getItem("user"));
  let loggedIn = localStorage.getItem("user") ? true : false;
  const history = useHistory();

  useEffect(() => {
    if (!loggedIn) {
      history.push("/");
      window.location.reload(false);
    }
    if (!loggedIn) return;

    url
      .get(`/order/customer/?customerID=${user[0]?.userID}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOrders(res.data.data.reverse());
        } else {
          // alert(res.data.data);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [orders, setOrders] = useState([]);

  // console.log(orders);

  return (
    <div className="dashboard orders">
      {!orders.length ? (
        <p style={{ marginTop: "20%", fontSize: "21px", color: "#000000" }}>
          <EmptiedWishlist message={"Orders"} />
        </p>
      ) : null}
      {orders.map((order, i) => (
        <div className="order-card" key={i}>
          <div className="order-upper">
            <div>
              <div>
                <span className="order-label">Order ID:</span>
                <span className="order-id"> {order.orderID.toUpperCase()}</span>
              </div>
              <div>
                <span className="order-label">Placed On:</span>
                <span className="order-placeOn">
                  {" "}
                  {moment(order.createdAt).format("LLLL")}
                </span>
              </div>
            </div>
            {/* <span>
              <p>Total:</p>
              <p style={{ marginTop: "-15px" }}>$ {order.finalPrice}</p>
            </span> */}
            <Link to={"/profile/order-details/" + order.orderID}>
              <button>ORDER DETAILS</button>
            </Link>
          </div>

          {/* <span>Shipment 1/1</span> */}
          <div className="item-list">
            {order.products.map((prod, j) => (
              <ItemCard
                name={prod.name}
                productID={prod.productID}
                qty={
                  prod.purchaseQuantity > 9
                    ? "" + prod.purchaseQuantity
                    : "0" + prod.purchaseQuantity
                }
                image={prod.image}
                discountPrice={prod.discountPrice}
                price={prod.price}
                key={j}
                status={order.status}
                selectedVariantData={prod.selectedVariantData}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Orders;
