import React, { useState } from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import AddAddress from "../../components/Profile/AddAddress";
import AddressList from "../../components/Profile/AddressList";
import EditProfile from "../../components/Profile/EditProfile";
import Loyalpoints from "../../components/Profile/LoyaltyPointsProfile";
//import Wishlist from "../../components/Profile/Wishlist";
import OrderDetails from "../../components/Profile/OrderDetails";
import Orders from "../../components/Profile/Orders";
import ProfileMain from "../../components/Profile/ProfileMain";
import ProfileSidebar from "../../components/Profile/ProfileSidebar";

// import Membership from "./components/Profile/Membership";
import "../../components/Profile/Profile.css";
import TopHeader from "../../components/TopHeader/TopHeader";

function Profile() {

 
  const [activeProfileStatus, setActiveProfileStatus] = useState("");
 
 //console.log(activeProfileStatus);
 
  return (
    <>
    <TopHeader pageName={"My Profile" }  subPageName ={activeProfileStatus ? activeProfileStatus : "" } />
    <Router>
      <Switch>
     
        <div className="profile">
          <div className="sidebar-profile hide">
            <ProfileSidebar setActiveProfileStatus={setActiveProfileStatus}/>
          </div>
          <div className="main-panel"></div>
          <Route path="/profile" exact component={ProfileMain} />
          {/* <Route path="/profile/membership" exact component={Membership} /> */}
          <Route path="/profile/orders"  component={Orders} />
          <Route path="/profile/loyaltyPoints"  component={Loyalpoints} />
          <Route path="/profile/edit-profile"  component={EditProfile} />
          <Route path="/profile/add-address"  component={AddAddress} />
          <Route path="/profile/all-address"  component={AddressList} />
          <Route path="/profile/order-details/:orderid"  component={OrderDetails} />
        </div>
      </Switch>
    </Router>
    </>
  );
}

export default Profile;
