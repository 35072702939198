import "./Membership.css";

function MembershipForm() {
  return (
    <>
      <div className="d-flex memb flex-column justify-content-center">
        <div className="row membership mt-5" style={{ width: "1240px" }}>
          <p>Membership / Cannabis ID</p>
          <div className="col">
            <div className="fields d-flex flex-column">
              <label htmlFor="patient">
                Patient ID<span className="text-red">*</span>
              </label>
              <input type="text" id="patient" />
              <label htmlFor="license">
                License Expiry<span className="text-red">*</span>
              </label>
              <input type="date" id="license" />
              <label htmlFor="address">Address</label>
              <input type="text" id="address" />
            </div>
          </div>
          <div className="col">
            <div className="fields d-flex flex-column">
              <label htmlFor="patient">
                Patient Birthdate<span className="text-red">*</span>
              </label>
              <input type="date" id="patient" />
              <label htmlFor="license">
                Card ID<span className="text-red">*</span>
              </label>
              <input type="text" id="license" />
              <label htmlFor="address">
                Upload Marijuana License Image<span className="text-red">*</span>
              </label>
              <input type="file" accept="image/*" id="address" />
            </div>
          </div>
        </div>
        <div className="mt-3 w-100 d-flex justify-content-center">
          <button className="w-25">SUBMIT</button>
        </div>
      </div>
    </>
  );
}

export default MembershipForm;
