import moment from "moment";
import React, { useContext } from "react";
import { ChatContext } from "../../Contexts/ChatContext";

import { Col, Row } from "react-bootstrap";
import Swal from "sweetalert2";

import url from "../../config/axios";
import notify from "../../Icons/notify-icon.svg";
import imgEmpty from "../../images/empty-mobile.png";

import "./notification.css";

const NotificationCard = () => {
  const { setNotificationCount } = useContext(ChatContext);

  const [notifications, setNotifications] = React.useState([]);
  const user = JSON.parse(localStorage.getItem("user"));
  const loggedIn = localStorage.getItem("user") ? true : false;

  React.useEffect(() => {
    //fetch notifications from server
    if (loggedIn == true) {
      GetNotifications();
    }
  }, []);

  const GetNotifications = () => {
    url
      .get(`/v1/notifications/users-paginated?userId=${user[0].userID}`)
      .then(async (res) => {
        //console.log("Here is my notifications", res.data);
        if (res.data.Message === "Success") {
          setNotifications(res?.data.data?.notifications);
        } else {
          //  alert(res.data.data);
        }
      });
  };
  const markAllAsRead = () => {
    url
      .patch(`/v1/notifications/toggle-seen-for-users?userId=${user[0].userID}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          Swal.fire("Successfully", "Status updated successfully", "success");
          GetNotifications();
          setNotificationCount(0);
        } else {
          //  alert(res.data.data);
        }
      });
  };

  return (
    <div>
      {loggedIn == true ? (
        notifications.length != 0 ? (
          <div className="container" style={{ marginBottom: "20px" }}>
            <div className="div-alignment">
              <p className="notification-heading">Notifications</p>
              <p className="mark-all" onClick={() => markAllAsRead()}>
                Mark All as Read
              </p>
            </div>
            {notifications.map((notification) => {
              return (
                <div className="notification-card">
                  <Row>
                    <Col>
                      <div className="notification-card-row">
                        <div>
                          <img
                            src={
                              notification?.notificationInfo?.imageUrl == ""
                                ? notify
                                : notification?.notificationInfo?.imageUrl
                            }
                            alt="notify-icons"
                            style={{ width: "2.3rem" }}
                          />
                        </div>
                        <div style={{ width: "100%" }}>
                          <div className="row">
                            <div className="col-7">
                              <p className="notification-heading-listing">
                                {notification?.notificationInfo?.title}
                              </p>
                            </div>
                            <div className="col">
                              <p className="notification-time-listing">
                                {moment(
                                  new Date(notification?.createdAt).getTime()
                                ).fromNow()}
                              </p>
                            </div>
                          </div>

                          <p className="notification-sub-heading-listing">
                            {notification?.notificationInfo?.highlights}
                          </p>
                          {/* <p className="notification-last-heading-listing">
                            {notification?.notificationInfo?.topic}
                          </p> */}
                        </div>
                      </div>
                    </Col>
                    {/* <Col xs={6}>
                      <p className="notification-time-listing">
                        {moment(new Date(notification?.createdAt).getTime()).fromNow()}
                      </p>
                    </Col> */}
                  </Row>
                </div>
              );
            })}
          </div>
        ) : (
          <div className="empty-wish">
            <img
              src={imgEmpty}
              alt=""
              style={{ width: "334px", height: "299px" }}
            />
            <p>Oops! No Notification Found..</p>
          </div>
        )
      ) : (
        <div className="empty-wish">
          <img
            src={imgEmpty}
            alt=""
            style={{ width: "334px", height: "299px" }}
          />
          <p>Oops! No Notification Found..</p>
        </div>
      )}
    </div>
  );
};

export default NotificationCard;
