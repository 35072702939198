import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { Row } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import config from "../../config";

let logo = require("../../images/" + config.logo);

export default function Starter() {
  const [btn, setBtn] = useState(false);
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  const history = useHistory();

  const checkAge_yes = () => {
    setBtn(true);
    localStorage.setItem("isAge", JSON.stringify(true));
    setShow(false);
  };

  const checkAge_no = () => {
    setBtn(false);
    history.push(`/`);
    setShow(false);
  };

  return (
    <Modal
      className="Starter-modal"
      show={show}
      onHide={handleClose}
      animation={false}>
      <Row>
        <div>
          {!btn ? (
            <div className="starter">
              {/* <div className='circle-1' ></div>
          <div className='circle-2' ></div> */}
              <span className="S-all">
                <div className="S-logo">
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "150px", maxHeight: "170px" }}
                  />
                  {/* <svg width="105" height="105" viewBox="0 0 105 105" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_712_1588)">
                  <path d="M52.5 105C81.4949 105 105 81.4949 105 52.5C105 23.505 81.4949 0 52.5 0C23.505 0 0 23.505 0 52.5C0 81.4949 23.505 105 52.5 105Z" fill="#FFC541" />
                  <path d="M79.7356 25.2328L25.233 79.7354L24.207 80.7614L48.276 104.83C49.6695 104.942 51.078 105 52.5002 105C81.4952 105 105 81.495 105 52.5C105 51.0778 104.942 49.6695 104.831 48.2758L80.7618 24.2068L79.7356 25.2328Z" fill="#FFE9B7" />
                  <path d="M15.5859 52.5C15.5859 72.887 32.113 89.4141 52.5 89.4141L58.5664 52.5L52.5 15.5859C32.113 15.5859 15.5859 32.113 15.5859 52.5Z" fill="white" />
                  <path d="M89.4141 52.5C89.4141 32.113 72.887 15.5859 52.5 15.5859V89.4141C72.887 89.4141 89.4141 72.887 89.4141 52.5Z" fill="#E9EDF5" />
                  <path d="M29.7261 78.8993L52.5 56.1254L53.3203 52.5L52.5 48.8746L26.1007 75.2739C20.6249 68.9458 17.6367 60.9527 17.6367 52.5C17.6367 43.1876 21.2631 34.4328 27.848 27.848C34.4328 21.2631 43.1876 17.6367 52.5 17.6367L53.3203 14.8983L52.5 12.5098C30.4139 12.5098 12.5098 30.4139 12.5098 52.5C12.5098 74.5861 30.4139 92.4902 52.5 92.4902L53.3203 90.3671L52.5 87.3633C44.0473 87.3633 36.0542 84.3751 29.7261 78.8993Z" fill="#FF7474" />
                  <path d="M92.4902 52.5C92.4902 30.4139 74.5861 12.5098 52.5 12.5098V17.6367C60.9527 17.6367 68.9458 20.6249 75.2739 26.1007L52.5 48.8746V56.1252L78.8993 29.7261C84.3751 36.0542 87.3633 44.0473 87.3633 52.5C87.3633 61.8124 83.7369 70.5672 77.152 77.152C70.5672 83.7369 61.8124 87.3633 52.5 87.3633V92.4902C74.5861 92.4902 92.4902 74.5861 92.4902 52.5Z" fill="#FA455C" />
                  <path d="M78.6157 38.1864H72.5682V32.1388H69.492V38.1864H63.2988V41.2625H69.492V47.4555H72.5682V41.2625H78.6157V38.1864Z" fill="#2B302D" />
                  <path d="M27.2271 34.6199V39.7468H33.7746V70.3801H38.9015V34.6199H27.2271Z" fill="#565656" />
                  <path d="M46.2051 58.548C46.2051 55.0768 49.029 52.2531 52.5 52.2531L53.7665 49.9306L52.5 47.1261C50.3524 47.1261 48.6051 45.3789 48.6051 43.2315C48.6051 41.0841 50.3524 39.3369 52.5 39.3369L53.7665 36.5402L52.5 34.2097C47.5254 34.2097 43.4782 38.2567 43.4782 43.2313C43.4782 45.5614 44.3661 47.6878 45.8216 49.2905C42.9509 51.3672 41.0781 54.7419 41.0781 58.548C41.0781 64.8461 46.202 69.9698 52.5 69.9698L53.7665 67.5913L52.5 64.843C49.029 64.843 46.2051 62.0191 46.2051 58.548Z" fill="#565656" />
                  <path d="M63.9218 58.548C63.9218 54.7419 62.0488 51.3674 59.1784 49.2905C60.6336 47.6881 61.5218 45.5614 61.5218 43.2313C61.5218 38.2567 57.4748 34.2097 52.5 34.2097V39.3367C54.6476 39.3367 56.3948 41.0839 56.3948 43.2313C56.3948 45.3787 54.6476 47.1259 52.5 47.1259V52.2529C55.9709 52.2529 58.7949 55.0768 58.7949 58.5478C58.7949 62.0187 55.9709 64.8426 52.5 64.8426V69.9696C58.7979 69.97 63.9218 64.8461 63.9218 58.548Z" fill="#2B302D" />
                </g>
                <defs>
                  <clipPath id="clip0_712_1588">
                    <rect width="105" height="105" fill="white" />
                  </clipPath>
                </defs>
              </svg> */}
                </div>
                {/* <span className='S-name' >{name}</span> */}
              </span>
              <p className="S-age">Hey! Are you 18 years old?</p>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <p className="S-title">
                  You must be over the age of 18 to access this site.
                </p>
              </div>
              <div className="S-button">
                <a href="https://www.google.com/ ">
                  <Button
                    className="S-BL"
                    onClick={() => {
                      checkAge_no();
                    }}
                    variant="danger">
                    No, I’m under 18
                  </Button>
                </a>

                <Button
                  className="S-BR colorBackground"
                  onClick={() => {
                    checkAge_yes();
                  }}
                  variant="success">
                  Yes I’m 18+
                </Button>
              </div>
              {/* <div className='circle-3'></div> */}
            </div>
          ) : null}
        </div>
      </Row>
    </Modal>
  );
}
