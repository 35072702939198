import React, { useEffect, useState, useContext } from "react";
import CartDetails from "../../components/Cart/CartDetails";
import CartItem from "../../components/Cart/CartItem";
import EmptiedWishlist from "../../components/Profile/EmptiedWishlist";
import { ChatContext } from "../../Contexts/ChatContext";
import TopHeader from "../../components/TopHeader/TopHeader";
import url from "../../config/axios";
import Swal from "sweetalert2";
import SkeletionLoadingCart from "../../components/Profile/SkeletionLoadingCart";

function Cart() {
  const {
    setCartCount,
    redirectUser,
    couponsProducts,
    couponDiscountValue,
    couponDiscountPercentage,
    setCouponsProducts,
    setCouponDiscountPercentage,
    setCouponDiscountValue,
  } = useContext(ChatContext);

  const [cart, setCart] = useState([]);
  const [skel, setskel] = useState(true);
  const [totalProd, setTotalProd] = useState(0);
  const [deleteProdCoupon, setDeleteProdCoupon] = useState(0);

  // console.log(cart);

  const token = JSON.parse(localStorage.getItem("Etoken"));

  const uid = JSON.parse(localStorage.getItem("user"));
  localStorage.setItem(
    "user",
    JSON.stringify(redirectUser.length !== 0 ? redirectUser : uid)
  );

  let loggedIn =
    localStorage.getItem("user") && uid?.length !== 0 && uid !== null
      ? true
      : false;

  useEffect(() => {
    if (!loggedIn) return;
    updateCart();
  }, [
    couponsProducts,
    couponDiscountValue[0]?.discountValue,
    couponDiscountPercentage[0]?.discountValue,
  ]);

  useEffect(() => {
    if (!loggedIn) return;
    getCart();
  }, []);

  let getCart = () => {
    if (!loggedIn) return;
    url
      .get(
        `/cart?customerId=${
          redirectUser.length !== 0 ? redirectUser[0]?.userID : uid[0]?.userID
        }`
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setCart(res.data.data[0]);
          setCartCount(res?.data?.data[0]?.products?.length);
          setTotalProd(res?.data?.data[0]?.products?.length);
          setskel(false);
          setDeleteProdCoupon(0);
        }
      });
  };

  let updateCart = () => {
    if (!loggedIn) return;
    url
      .get(
        `/cart?customerId=${
          redirectUser.length !== 0 ? redirectUser[0]?.userID : uid[0]?.userID
        }`
      )
      .then(async (res) => {
        if (res.data.Message === "Success") {
          //getCart();
          if (couponsProducts.length !== 0) {
            let arr = res.data.data[0];
            couponsProducts.map((p) => {
              arr.products.map((e) => {
                if (e.productID === p.productID && !e.product.discountPrice) {
                  if (couponDiscountValue[0]?.discountValue) {
                    if (!e.product.discountPrice) {
                      e.product.originalPrice = e.product.discountPrice;
                    }
                    e.product.discountPrice =
                      e.product.price - couponDiscountValue[0].discountValue;
                  } else if (couponDiscountPercentage[0]?.discountValue) {
                    if (!e.product.discountPrice) {
                      e.product.originalPrice = e.product.discountPrice;
                    }
                    e.product.discountPrice =
                      e.product.price -
                      (e.product.price / 100) *
                        parseInt(couponDiscountPercentage[0]?.discountValue);
                  }
                  setDeleteProdCoupon(0);
                }
              });
              setCart(arr);
              // console.log(arr.products[x].product.discountPrice);
            });
          } else {
            //getCart();
            setCart(res.data.data[0]);
          }
          setCartCount(res?.data?.data[0]?.products?.length);
          setTotalProd(res?.data?.data[0]?.products?.length);
          setDeleteProdCoupon(0);
          setskel(false);
        } else {
        }
      });
  };

  const UpdateQuantity = (obj, pq, v) => {
    let ft = [];
    let final = [];
    if (loggedIn) {
      cart.products.map((k1, i) => {
        if (k1?.variant?.weight) {
          if (
            k1.productID === obj.productID &&
            k1.variant.weight === v.weight
          ) {
            k1.product.purchaseQuantity = pq;
            if (k1.product.originalPrice || k1.product.originalPrice === null) {
              k1.product.discountPrice = k1.product.originalPrice;
            }
            ft.push(cart);
          }
        } else {
          if (k1.productID === obj.productID) {
            k1.product.purchaseQuantity = pq;
            if (k1.product.originalPrice || k1.product.originalPrice === null) {
              k1.product.discountPrice = k1.product.originalPrice;
            }
            ft.push(cart);
          }
        }
      });
      ft[0].products.map((e) => {
        if (e.product.originalPrice || e.product.originalPrice === null) {
          e.product.discountPrice = e.product.originalPrice;
        }
        final.push(ft[0]);
      });
      // return console.log(final[0]);
      url
        .put(
          `/v1/cart/update-products`,
          {
            products: final[0].products,
            totalPrice: 0,
            status: true,
          },
          {
            headers: {
              auth: token,
            },
          }
        )
        .then(async (res) => {
          if (res.data.Message === "Success") {
            setDeleteProdCoupon(0);
            updateCart();
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "warning",
              title: "Cart updated successfully",
            });
          } else {
            const Toast = Swal.mixin({
              toast: true,
              position: "bottom-right",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
                toast.addEventListener("mouseenter", Swal.stopTimer);
                toast.addEventListener("mouseleave", Swal.resumeTimer);
              },
            });
            Toast.fire({
              icon: "warning",
              title: res.data.message,
            });
          }
        });
    }
  };

  const delCoupon = () => {
    setCouponsProducts([]);
    setCouponDiscountPercentage([]);
    setCouponDiscountValue([]);
  };

  const removeCart = (index) => {
    let final = [];
    if (loggedIn) {
      cart?.products.splice(index, 1);

      cart.products.map((e) => {
        if (e.product.originalPrice || e.product.originalPrice === null) {
          e.product.discountPrice = e.product.originalPrice;
        }
        final.push(cart);
      });
      //console.log(cart.products);
      //return console.log(final[0]);
      url
        .put(
          `/v1/cart/update-products`,
          {
            products: final[0]?.products ? final[0]?.products : cart.products,
            totalPrice: 0,
            status: true,
          },
          {
            headers: {
              auth: token,
            },
          }
        )
        .then(async (res) => {
          //getCart();
          delCoupon();
          updateCart();
          setDeleteProdCoupon(1);
          const Toast = Swal.mixin({
            toast: true,
            position: "bottom-right",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener("mouseenter", Swal.stopTimer);
              toast.addEventListener("mouseleave", Swal.resumeTimer);
            },
          });
          Toast.fire({
            icon: "success",
            title: "Product successfully deleted",
          });
        });
    }
  };

  const chkoutremove = (cartId) => {
    url.delete(`/cart/all/?cartId=${cartId}`).then(async (res) => {
      await getCart();
    });
    setCart([]);
    setCartCount(0);
  };

  return (
    <>
      <TopHeader pageName={"My Cart"} />

      <div className="cart">
        {totalProd === 0 || !totalProd ? (
          <EmptiedWishlist message={"cart"} />
        ) : (
          <div
            className="row"
            style={{ textAlign: "-webkit-center", marginTop: "50px" }}>
            <div className="upper-title">
              <div
                className="row"
                style={{ width: "100%", alignItems: "center" }}>
                <div
                  className="col-5 mycartleft"
                  style={{ width: "25%" }}></div>
              </div>
            </div>
            <div className="col carditemleft">
              <div className="cart-topbar">
                <div className="cart-topbar-products">Products</div>

                <div className="cart-topbar-product-length">
                  <span>{totalProd}</span>&nbsp;
                  <span>{totalProd <= 1 ? "Item" : "Items"}</span>
                </div>
              </div>
              <div className="cart-align" style={{ marginBottom: "80px" }}>
                {skel ? (
                  <SkeletionLoadingCart />
                ) : (
                  cart?.products?.map((c, i1) => {
                    return (
                      <CartItem
                        product={c?.product}
                        variant={c?.variant}
                        cardId={cart?.cartId}
                        index={i1}
                        removeCart={removeCart}
                        UpdateQuantity={UpdateQuantity}
                        couponsProducts={couponsProducts}
                      />
                    );
                  })
                )}
              </div>
            </div>
            <div className="col" style={{ display: "flex" }}>
              <CartDetails
                product={cart}
                chkoutremove={chkoutremove}
                updateCart={updateCart}
                deleteProdCoupon={deleteProdCoupon}
                currency={
                  cart?.products?.length > 0
                    ? cart?.products[0]?.variant?.pricingInfo?.currency
                      ? cart?.products[0]?.variant?.pricingInfo?.currency ===
                        "USD"
                        ? "$"
                        : cart?.products[0]?.variant?.pricingInfo?.currency +
                          "$"
                      : "$"
                    : "$ "
                }
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default Cart;
