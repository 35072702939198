import React, { useEffect, useState } from "react";
import { Col, Modal, Row } from "react-bootstrap";
import config from "../../config";

import url from "../../config/axios";
import storeIcon from "../../Icons/store.svg";
import { useHistory } from "react-router-dom";
import "./StoreModal.css";

function StoreModal({ store }) {
  const history = useHistory();
  const [, setShow] = useState(store);
  const [outlets, setOutlets] = useState([]);
  useEffect(() => {
    GetAllOutlets();

  }, []);



  const GetAllOutlets = () => {
    url.get(`/oc/?consumerId=${config.consumerId}`).then(async (res) => {
      // url.get(`/oca/consumer/?consumerId=1`).then(async (res) => {

      if (res.data.Message === "Success") {
        setOutlets(res.data.data);
      } else {
        //  alert(res.data.data);
      }
    });
  };

  const selectStore = (id, name ,address) => {


    localStorage.setItem("outletname", JSON.stringify(name));
    localStorage.setItem("outletChainID", JSON.stringify(id));
    localStorage.setItem("outletAddress", JSON.stringify(address));
    history.push(`/`)
    window.location.reload();
    
  };

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={store} onHide={handleClose} backdrop="static" keyboard={false} className="blur" centered>
        <Modal.Header>
          <Row className="store-selector">
            <p>Select an Outlet</p>
            <p>Based on your location</p>
          </Row>
        </Modal.Header>
        <Modal.Body>
          <div className="scroll">
            {outlets.map((out, i) => (
              <Row className="store" key={i} >
                <Col className="d-flex" style={{ cursor: "pointer" }} onClick={() => selectStore(out.outletChainID, out.outletName, out.address)}>
                  <img src={storeIcon} alt="Icon" />
                  <p className="m-0 mx-3" >{out.outletName}</p>
                </Col>
              </Row>
            ))}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StoreModal;
