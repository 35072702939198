import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";

import DealProductItem from "../../components/Products/DealProductItem";
import SkeletionLoading from "../../components/SkeletionLoading";
import url from "../../config/axios";
import { ChatContext } from "../../Contexts/ChatContext";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import TopHeader from "../../components/TopHeader/TopHeader";

export default function DealProduct() {
  const params = useParams();

  const { setWishCount } = useContext(ChatContext);

  const [products, setProducts] = useState([]);
  const [dealCat, setDealCat] = useState([]);
  const [skel, setSkel] = useState(true);
  const [foundProduct, setFoundProduct] = useState([]);
  const [wish, setWish] = useState([]);

  const chainID = JSON.parse(localStorage.getItem("outletChainID"));
  const DealsDiscounts = JSON.parse(localStorage.getItem("DealsDiscounts"));
  const selectedCategories = JSON.parse(
    localStorage.getItem("selectedCategories")
  );
  const uid = JSON.parse(localStorage.getItem("user"));
  let loggedIn = localStorage.getItem("user") ? true : false;

  useEffect(() => {
    if (!products) {
      Swal.fire("Missing", "No product Found or Number", "warning");
    }
    getProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProducts = () => {
    url
      .get(`/v1/deals/products-under-deal?dealID=${params?.dealId}`)
      .then(async (res) => {
        // console.log(res);
        if (res.data.Message === "Success") {
          setProducts(res?.data?.data?.products);

          setSkel(false);
        } else console.log(res.data);
      });
  };

  useEffect(() => {
    selectedCategories?.map((e4) => {
      setDealCat(e4?.categoryID);
    });
    if (params?.dealId || params?.category === dealCat) {
      let dis = DealsDiscounts ? Object.keys(DealsDiscounts) : null;
      let disEntries = DealsDiscounts ? Object.entries(DealsDiscounts) : null;
      let pd = products;
      let pf = foundProduct;

      pd.map((e) => {
        let xd = dis?.filter((d) => d === e?.product?.productID);

        let prod = pd?.filter((p) => p?.product?.productID === xd[0]);
        pf.push(prod);

        disEntries.map((e1, i1) => {
          pf.map((e2, i2) => {
            // console.log(e2);
            if (e1[0] === e2[0]?.product?.productID) {
              e2[0].discountPercentage = e1[1].discountValue;
              e2[0].discountPrice = e2[0].originalPrice
                ? e2[0].originalPrice -
                  (e2[0].originalPrice * e1[1].discountValue) / 100
                : e2[0].variants[0].price -
                  (e2[0].originalPrice
                    ? e2[0].originalPrice
                    : (e2[0].variants[0].price * e1[1].discountValue) / 100);

              return;
            }
            // setProducts(pf);
          });
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DealsDiscounts]);

  const addFav = (e, prod) => {
    if (!loggedIn) return;
    //console.log(prod);
    if (e) {
      let WBody = {
        outletId: chainID,
        customerId: uid[0]?.userID,
        userID: uid[0]?.userID,
        product: {
          productID: prod.productID,
          quantity: prod?.invertory,
          originalPrice: prod?.originalPrice,
          outletChainID: chainID,
          variants: [prod?.selectedVariantData],
          product: {
            productID: prod.productID,
            name: prod.name,
            quantity: prod?.invertory,
            image: prod?.image,
            price: prod?.vPrice ? prod?.vPrice : prod.originalPrice,
            discountPrice: prod?.discountPrice,
            brandName: prod.brandName,
            brandID: prod.brandID,
            invertory: prod?.invertory,
            purchaseQuantity: 1,
          },
        },
        status: true,
      };
      //   return console.log(WBody);
      url
        .post(`/wishlist/`, WBody)
        .then(async (res) => {
          if (res.data.Message === "Failure") console.log(res.data);
          else {
            getWishItems();
          }
        })
        .catch((e) => console.log(e));
    } else {
      let x = wish;
      let id = x.findIndex((k) => k.product.productID === prod.productID);

      url
        .delete(`/wishlist/?wishlistID=${x[id]?.wishlistID}`)
        .then((res) => {
          if (res.data.Message === "Success") {
            x.splice(id, 1);
            // setWish(x)
            getWishItems();
          } else console.log(res.data);
        })
        .catch((e) => console.log(e));
    }
  };

  const getWishItems = () => {
    if (!loggedIn) return;
    url
      .get(`/wishlist/?userID=${uid[0].userID}`)
      .then((res) => {
        if (res.data.Message === "Success") {
          setWish(res.data.data);
          setWishCount(res.data.data.length);

          if (res.data.data.length < 1) setWish([]);
        } else {
          //alert(res.data.data);
        }
      })
      .catch((e) => console.log(e));
  };

  return (
    <>
      <TopHeader pageName={"Deals"} />

      <div style={{ width: "100%" }}>
        <div
          className="products-list"
          style={{ margin: "30px", alignItems: "center" }}>
          {skel ? (
            <div style={{ display: "flex" }} className="skelloading">
              {" "}
              <SkeletionLoading /> <SkeletionLoading /> <SkeletionLoading />{" "}
            </div>
          ) : (
            <>
              {products?.length >= 1 ? (
                <div className="list">
                  {products?.map((prod, i) => {
                    return (
                      <DealProductItem
                        image={prod?.product?.image}
                        productID={prod?.productID}
                        name={prod?.product?.name.replace("%", " ")}
                        originalPrice={prod?.originalPrice}
                        discountPrice={prod?.discountPrice}
                        discountPercentage={prod?.discountPercentage}
                        vPrice={
                          prod?.originalPrice
                            ? prod?.originalPrice
                            : prod?.variants[0]?.price
                        }
                        currency={
                          prod?.variants[0]?.pricingInfo?.currency
                            ? prod?.variants[0]?.pricingInfo?.currency === "USD"
                              ? "$"
                              : prod?.variants[0]?.pricingInfo?.currency + "$"
                            : "$"
                        }
                        vWeight={prod?.variants[0]?.weight}
                        vUom={prod?.variants[0]?.uom}
                        key={i}
                        wish={wish}
                        addFav={addFav}
                        selectedVariantData={prod?.variants[0]}
                        cbd={prod?.product?.cbd}
                        thc={prod?.product?.thc}
                        invertory={prod?.quantity}
                      />
                    );
                  })}
                  {/* <SkeletionLoading/> */}
                </div>
              ) : (
                <div
                  className="row"
                  style={{ marginTop: "10%", display: "block" }}>
                  <div className="col" style={{ textAlign: "center" }}>
                    <img
                      src={noimg}
                      alt="noimg"
                      style={{
                        width: "20%",
                        alignSelf: "center",
                        opacity: "50%",
                        marginBottom: "3%",
                      }}
                    />
                  </div>
                  <div
                    className="col"
                    style={{ textAlign: "center", marginBottom: "6%" }}>
                    <span style={{ fontSize: "33px", marginTop: "10%" }}>
                      No Product Found
                    </span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
