import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";
import { Link } from "react-router-dom";
import { Tooltip } from "@nextui-org/react";
import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import greenPlaceholder from "../../images/Green_placeholder.png";

import "../../App.css";

function CategoriesCard(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [skel, setskel] = useState(true);

  useEffect(() => {
    if (!props?.image) setIsLoaded(true);
    setskel(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Link
        to={"/products/category/" + props.categoryID}
        style={{ textDecoration: "none" }}>
        {/* <div className="cat-card">
          <span>{props.productCount}</span>
          <span>{props.name}</span>
          <img src={props.image} style={{ width: "100px", heigth: "100px" }} alt="product" />
        </div> */}

        {/* {props.productCount === 0 ? null : */}

        <Card
          className="phone-allcat "
          style={{
            paddingBottom: "13px",
            width: "270px",
            height: "231px",
            marginRight: "20px",
            marginBottom: "20px",
          }}>
          <Card.Body
            className="mobile-allcat allCategories"
            style={{ marginLeft: "15px" }}>
            {!skel ? (
              <Card.Title style={{ fontWeight: "400" }}>
                {props.productCount}{" "}
                {props.productCount === 1 ? "Item" : "Items"}{" "}
              </Card.Title>
            ) : (
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} style={{ borderRadius: "10px" }} />
              </Placeholder>
            )}

            {!skel ? (
              props?.name?.length < 15 ? (
                <Card.Text
                  style={{
                    fontSize: "20px",
                    fontWeight: "500",
                    lineHeight: "30px",
                  }}>
                  {props?.name?.toUpperCase()}
                </Card.Text>
              ) : (
                <Tooltip
                  content={props?.name}
                  color="success"
                  rounded={false}
                  placement={"top"}>
                  <Card.Text
                    className="phone-allcat-name"
                    style={{
                      fontSize: "20px",
                      fontWeight: "500",
                      lineHeight: "30px",
                    }}>
                    {props?.name?.toUpperCase()?.substring(0, 15)}...
                  </Card.Text>
                </Tooltip>
              )
            ) : (
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} style={{ borderRadius: "10px" }} />
              </Placeholder>
            )}
          </Card.Body>
          {!props.image ? (
            <Card.Img
              className="phone-img"
              variant="bottom"
              style={{
                width: "100px",
                marginBottom: "12%",
                alignSelf: "center",
              }}
              src={noimg}
            />
          ) : (
            <Card.Img
              className="phone-allcatImg phone-img"
              variant="bottom"
              style={{
                width: "157px",
                marginBottom: "12%",
                alignSelf: "center",
                display: !isLoaded ? "none" : "flex",
              }}
              src={props?.image}
              onLoad={() => {
                setIsLoaded(true);
              }}
            />
          )}
          {!isLoaded ? (
            <Card.Img
              variant="bottom "
              className="phoneLoadImgCard loadingimg phone-img"
              src={greenPlaceholder}
            />
          ) : (
            ""
          )}
        </Card>
      </Link>
    </>
  );
}

export default CategoriesCard;
