import React, { useEffect, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import url from "../config/axios";
import Swal from "sweetalert2";
import { ChatContext } from "../Contexts/ChatContext";
import config from "../config";

export default function Redirect() {
  const { setRedirectUser, setRedirectOutletName, setRedirectChainID } =
    useContext(ChatContext);

  //const params = useParams();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  let history = useHistory();

  useEffect(() => {
    getAuth();
    //  console.log(params.get("token"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAllOutlets = (c) => {
    url
      .get(`/oc/?consumerId=${config.consumerId}`)
      .then(async (res) => {
        // console.log(res.data.data);
        if (res.data.Message === "Success") {
          if (res.data.data.length > 1) {
            let x = res.data.data.filter((e) => e.outletChainID === c);
            console.log(x[0]);
            // console.log(x[0].outletChainID);
            // console.log(x[0].outletName);
            // console.log(x[0].helpContact);
            localStorage.setItem(
              "outletChainID",
              JSON.stringify(x[0].outletChainID)
            );

            localStorage.setItem("outletname", JSON.stringify(x[0].outletName));
            setRedirectOutletName(x[0].outletName);
            localStorage.setItem(
              "helpContact",
              JSON.stringify(x[0].helpContact ? x[0].helpContact : null)
            );
            // console.log(res.data.data);
          }
          // window.location.reload();
        }
      })
      .catch(async (err) => {
        console.log(err);
      });
  };

  const getAuth = () => {
    // let redirectToken = params.token;

    let tokenAuth = {
      token: params.get("token"),
    };
    let RedirectHeaders = {
      outletchainid: params.get("outletChainID"),
      consumerid: params.get("consumerID"),
    };

    // console.log(tokenAuth);
    // return console.log(RedirectHeaders);
    // return;

    url
      .post(`/v1/orders/get-redirection-info`, tokenAuth, {
        headers: RedirectHeaders,
      })
      .then(async (res) => {
        setRedirectUser([res?.data?.data?.userInfo]);

        if (res.data.Message === "Success") {
          setRedirectChainID(res?.data?.data?.redirectionInfo.outletChainID);
          await GetAllOutlets(res?.data?.data?.redirectionInfo.outletChainID);
          localStorage.setItem(
            "user",
            JSON.stringify([res?.data?.data?.userInfo])
          );

          localStorage.setItem(
            "Etoken",
            JSON.stringify(res?.data?.data?.token)
          );

          Swal.fire({
            icon: "success",
            title: "Redirecting....",
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            if (result) {
              window.location.reload();
            }
          });
          // await setRedirectChainID(
          //   res?.data?.data?.redirectionInfo.outletChainID
          // );

          {
            res?.data?.data?.redirectionInfo?.productInfo?.productID
              ? history.push(
                  "/product/" +
                    res?.data?.data?.redirectionInfo?.productInfo?.productID
                )
              : history.push("/cart");
          }
          //window.location.reload(false);
        } else {
          Swal.fire({
            icon: "error",
            title: "Fail to Redirect",
            confirmButtonText: "Back to Home",
          }).then((result) => {
            if (result.isConfirmed) {
              history.push("/");
            }
          });
        }
      })
      .catch((e) => {
        Swal.fire({
          icon: "error",
          title: "Fail to Redirect",
          confirmButtonText: "Back to Home",
        }).then((result) => {
          if (result.isConfirmed) {
            history.push("/");
          }
        });
      });
  };

  return (
    <div>
      <div className="container" style={{ height: "400px" }}>
        We will connect with you soon.....
      </div>
    </div>
  );
}
