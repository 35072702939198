import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import moment from "moment";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import Input from "react-phone-number-input/input";
import url from "../../config/axios";
import "react-phone-number-input/style.css";

function Dashboard() {
  const [count, setCount] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [date, setDate] = useState("");
  const [value, setValue] = useState("");
  const [userImage, setUserImage] = useState("");

  const uid = JSON.parse(localStorage.getItem("uid"));

  const user = JSON.parse(localStorage.getItem("user"));
  let loggedIn = localStorage.getItem("user") ? true : false;

  const history = useHistory();

  useEffect(() => {
    if (loggedIn) {
      setUserImage(user[0]?.image);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!loggedIn) {
      history.push("/");
      window.location.reload(false);
    }
    getWishItems();
    GetAllOrders();
    setDate(user[0]?.dob ? moment().format(user[0]?.dob?.split("T")[0]) : "");

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAllOrders = () => {
    if (!loggedIn) return;
    url
      .get(`/order/customer/?customerID=${user[0]?.userID}`)
      .then(async (res) => {
        if (res.data.Message === "Success") {
          setOrderCount(res.data.data.length);
        } else {
        }
      });
  };

  const getWishItems = () => {
    if (!loggedIn) return;
    url
      .get(`/wishlist/?userID=${user[0]?.userID}`)
      .then((res) => {
        setCount(res.data.data.length);
      })
      .catch((e) => console.log(e));
  };

  return (
    <div className="dashboard">
      {loggedIn ? (
        <div className="profile-banner">
          <div
            className="row phone-profile-bannerRow"
            style={{ width: "100%" }}>
            <div className="col-8">
              <div className="pdetails" style={{ width: "93%" }}>
                {user[0]?.image ? (
                  <>
                    <img src={user[0]?.image} alt="" />
                  </>
                ) : (
                  <span className="Ep-FirstLetter">
                    {user[0]?.name[0]?.toUpperCase()}
                  </span>
                )}
                <div
                  className="phone-pUser-Name"
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    marginLeft: "30px",
                  }}>
                  <p className="pName">{user[0]?.name}</p>
                  {/* <span className="pAddress">Alaska, United States</span> */}
                </div>
              </div>
            </div>
            <div
              className="col phone-profile-bannerRow"
              style={{ display: "flex", justifyContent: "flex-end" }}>
              <Link to="/profile/edit-profile">
                <button>
                  Edit Profile
                  <i className="fas fa-pencil-alt" />
                </button>
              </Link>
            </div>
          </div>
        </div>
      ) : null}

      <div className="row" style={{ marginBottom: "22px" }}>
        <div className="col">
          <span className="Ep-label">
            Full Name*
            <br />
            <input
              disabled
              style={{ width: "100%" }}
              type="text"
              name=""
              id=""
              placeholder="Enter your Name"
              defaultValue={!user[0] ? "" : user[0]?.name}
            />
          </span>
        </div>

        <div className="col">
          <span className="Ep-label">
            Email*
            <br />
            <input
              disabled
              style={{ width: "100%" }}
              type="email"
              name=""
              id=""
              placeholder="Enter Your Email"
              defaultValue={user[0]?.email}
            />
          </span>
        </div>
      </div>

      <div className="row" style={{ marginBottom: "22px" }}>
        <div className="col">
          <div
            className="Ep-mobilenumber-lable"
            style={{ marginBottom: "22px" }}>
            <span className="Ep-label" style={{ width: "119px" }}>
              Mobile Number
            </span>
            {/* <span className="Ep-label">Mobile Number</span> */}
          </div>
          <div
            style={{
              marginTop: "-18px",
              display: "flex",
              flexDirection: "row",
            }}>
            <Input
              className="Ep-numberNumber"
              style={{ width: "100%" }}
              placeholder="Enter phone number"
              disabled
              value={user[0]?.mobileNo ? user[0]?.mobileNo : uid?.phoneNumber}
              error={
                value
                  ? isValidPhoneNumber(value)
                    ? undefined
                    : "Invalid phone number"
                  : "Phone number required"
              }
            />
          </div>
        </div>

        <div className="col" style={{ display: "flex" }}>
          <span className="Ep-label">
            Gender*
            <br />
            <input
              disabled
              className="Ep-selectGender"
              value={user[0].gender}
              placeholder="Gender"
            />
          </span>
        </div>
      </div>

      <div
        className="row"
        style={{ marginBottom: "22px", display: "flex", alignItems: "center" }}>
        <div className="col">
          <div className="Ep-label">
            <span className="Ep-label">Date Of Birth*</span>
            <br />

            <input
              disabled
              style={{ width: "100%" }}
              type="text"
              value={moment(date).format("MM-DD-YYYY")}
            />
          </div>
        </div>

        <div className="col" style={{ marginTop: "-15px" }}>
          <span style={{ flexDirection: "row" }}>
            <span className="Ep-label">
              Profession*
              <br />
              <select
                disabled
                className="Ep-selectGender"
                value={user[0].profession}
                placeholder="Profession">
                <option value="Teachers">Teacher</option>
                <option value="Veterans">Veteran</option>
                <option value="FireFighter">FireFighter</option>
                <option value="Nurse">Nurse</option>
                <option value="Others">Other</option>
              </select>
            </span>
          </span>
        </div>
      </div>

      <div
        className="row"
        style={{ marginBottom: "22px", display: "flex", alignItems: "center" }}>
        <div className="col" style={{ marginTop: "-15px" }}>
          <span style={{ flexDirection: "row" }}>
            <span className="Ep-label">
              Medical License Number*
              <br />
              <input
                disabled
                style={{ width: "100%" }}
                value={
                  user[0].medicalLicense
                    ? user[0].medicalLicense
                    : uid?.medicalLicense
                }
                type="tel"
                name=""
                id=""
                placeholder="A1234455612"
              />
            </span>
          </span>
        </div>

        <div className="col">
          <div className="Ep-label">
            <span className="Ep-label">Medical License Expiry Date*</span>
            <br />

            <input
              disabled
              style={{ width: "100%" }}
              value={
                user[0].mlExpiry
                  ? moment(user[0].mlExpiry).format("MM-DD-YYYY")
                  : ""
              }
              type="text"
              min={moment().format("YYYY-MM-DD")}
            />
          </div>
        </div>
      </div>

      <div className="row" style={{ marginBottom: "22px" }}>
        <span className="Ep-label">
          Drivers License Number*
          <br />
          <input
            disabled
            style={{ width: "100%" }}
            value={
              user[0].drivingLicense
                ? user[0].drivingLicense
                : uid?.drivingLicense
            }
            type="tel"
            name=""
            id=""
            placeholder="DDWDLFB34445XX01223"
          />
        </span>
      </div>

      <div className="row" style={{ marginBottom: "22px" }}>
        <span className="Ep-label">
          Password*
          <br />
          <input
            disabled
            style={{ width: "100%" }}
            type="password"
            name=""
            id=""
            placeholder="Enter your Name"
            defaultValue={"aaaaaaaa"}
          />
        </span>
      </div>
    </div>
  );
}

export default Dashboard;
