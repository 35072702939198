import { useState } from "react";

import location from "../../Icons/location-add.svg";
import locationDrop from "../../Icons/location-drop.svg";
import google from "../../images/google.svg";
import MapModal from "./MapModal";

function AddressModal({ addAddress }) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <div className="modals">
        <div className="overlay" onClick={() => addAddress(false)}></div>
        <div className="address-modal">
          <div className="address-top mb-2">
            <span>Search Location</span>
            <i className="fas fa-chevron-left" />
          </div>
          <div className="address-search">
            <i className="fas fa-search" />
            <input type="text" placeholder="Search for your location..." id="" />
          </div>
          <div className="address-current">
            <img src={location} alt="" className="ms-2" />
            <span className="ms-3 dangerColor">
              Use Current Location
            </span>
          </div>
          <hr />
          <div className="address-item" onClick={() => setVisible(true)}>
            <img src={locationDrop} alt="locdrop" />
            <span className="address-item-det">
              <span>Sierra Nevada</span>
              <span>Spain</span>
            </span>
          </div>
          <img src={google} alt="google" height="30px" className="mt-3" />
          {visible && <MapModal visible={setVisible} />}
        </div>
      </div>
    </>
  );
}

export default AddressModal;
