import { Tooltip } from "@nextui-org/react";
import { useEffect, useState } from "react";
import Card from "react-bootstrap/Card";
import Placeholder from "react-bootstrap/Placeholder";
import { Link } from "react-router-dom";

import noimg from "../../images/Bloom Icon (Filled) 1 (1).png";
import greenPlaceholder from "../../images/Green_placeholder.png";

import "../../App.css";

function BrandsCard(props) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [skel, setskel] = useState(true);

  useEffect(() => {
    if (!props?.image) setIsLoaded(true);
    setskel(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // console.log(props);

  return (
    <>
      <Link
        to={"/products/manufacturers/" + props.brandID}
        style={{ textDecoration: "none" }}>
        <Card
          className="phone-allcat "
          style={{
            background: "#F7FAFC",
            borderRadius: "10px",
            paddingBottom: "13px",
            width: "270px",
            height: "270px",
            marginBottom: "23px",
            marginRight: "20px",
            marginBottom: "20px",
          }}>
          <Card.Body
            className="allCategories allbrand-phone"
            style={{ marginLeft: "15px" }}>
            {!skel ? (
              <Card.Title>
                {props.productCount}{" "}
                {props.productCount === 1 ? "Item" : "Items"}{" "}
              </Card.Title>
            ) : (
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} style={{ borderRadius: "10px" }} />
              </Placeholder>
            )}

            {!skel ? (
              props.name.length < 15 ? (
                <Card.Text className="all-brands-name">{props?.name}</Card.Text>
              ) : (
                <Tooltip
                  content={props?.name}
                  color="success"
                  rounded={false}
                  placement={"top"}>
                  <Card.Text className=" phone-all all-brands-name">
                    {props?.name.substring(0, 15)}...{" "}
                  </Card.Text>
                </Tooltip>
              )
            ) : (
              <Placeholder as={Card.Title} animation="glow">
                <Placeholder xs={6} style={{ borderRadius: "10px" }} />
              </Placeholder>
            )}
          </Card.Body>
          {!props.image ? (
            <Card.Img
              className="phone-img"
              variant="bottom"
              style={{
                width: "100px",
                marginBottom: "12%",
                alignSelf: "center",
              }}
              src={noimg}
            />
          ) : (
            <Card.Img
              className="  phone-img all-brands-img"
              variant="bottom"
              style={{
                width: "157px",
                marginBottom: "12%",
                alignSelf: "center",
                display: !isLoaded ? "none" : "flex",
              }}
              src={props?.image}
              onLoad={() => {
                setIsLoaded(true);
              }}
            />
          )}
          {!isLoaded ? (
            <Card.Img
              variant="bottom"
              style={{ marginTop: "128px", position: "absolute" }}
              className="phoneLoadImgCard loadingimg phone-img"
              src={greenPlaceholder}
            />
          ) : (
            ""
          )}
        </Card>
      </Link>
    </>
  );
}

export default BrandsCard;
