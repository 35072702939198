import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swal from 'sweetalert2'
import Carousel from 'better-react-carousel'

import url from "../../config/axios";
import CategoriesCard from "../Categories/CategoriesCard";

function Categories() {
  const [category, setCategory] = useState([]);

  const chainID = JSON.parse(localStorage.getItem("outletChainID"));

  useEffect(() => {
    url.get(`/category/outlet?OutletId=${chainID}`).then(async (res) => {

      if (res.data.Message === "Success") {
        setCategory(res.data.data);
      } else {
        Swal.fire(
          'Category',
          'Not Found ',
          'error'
        )
      }
    });
  }, [chainID]);

  

  return (

    <>

      {category?.length === 0 ? null :
        <div className="categories">
          <div className="cat-titles">
            <span style={{ paddingBottom: "15px" }}>Categories</span>
            <span >
              <Link to="/categories" className="secondColor" style={{ textDecoration: "none", color: "#22B34B" }} >View All</Link>
            </span>

          </div>

          <div className="main-cat " >
       
          <Carousel cols={4} rows={1} gap={12} width="100" style={{width:"100%"}}>
            {
              category.map((cat, i) => {
                return(
                  <Carousel.Item style={{width:"100%"}} >
                <CategoriesCard productCount={cat?.countInventory[0]?.count} name={cat?.name} image={cat?.image} key={i} categoryID={cat?.categoryID} />
                </Carousel.Item >
              )})}
            </Carousel>
          </div>
        </div>}
    </>
  );
}

export default Categories;