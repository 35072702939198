import Categories from "../../components/Home/Categories";
import Popular from "../../components/Home/Popular";
//import Slider from "../../components/Home/Slider";
//import Sideicons from "./components/Sideicons";
import ProductCards from "../../components/Home/ProductCards";
import Starter from "../../components/Home/Starter";
import Trending from "../../components/Home/Trending";
import Banner from "../../components/Homepage/Banner.jsx";
import vector1 from "../../images/Vector 8.png";
import vector2 from "../../images/Vector 9.png";
import fullBanner from "../../images/fullBanner.png";
import storeIcon from "../../Icons/store.svg";
import HitStoreModal from "../../components/Homepage/HitStoreModal";
import StoreModal from "../../components/Homepage/StoreModal";
import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import config from "../../config";
import url from "../../config/axios";
import "../../App.css";
import { useEffect, useState } from "react";
import LiveChat from "../../components/locations/LiveChat";
import Infobanner from "../../components/Home/Infobanner";

function Home() {
  const storeName = localStorage.getItem("outletname");
  let strD = storeName ? storeName : "Select an Outlet";
  const [storeS, setStoreS] = useState(false);
  const [store, setStore] = useState(strD);
  const [sDisplay, setSDisplay] = useState(false);
  const [outlets, setOutlets] = useState([]);
  const [isMultiChain, setIsMultiChain] = useState(false);

  const isAge = JSON.parse(localStorage.getItem("isAge"));
  let str = outlets[0]?.outletName
    ? outlets[0]?.outletName
    : "Select an Outlet";
  //console.log(wishCount);

  useEffect(() => {
    GetAllOutlets();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GetAllOutlets = () => {
    url.get(`/oc/?consumerId=${config.consumerId}`).then(async (res) => {
      if (res.data.Message === "Success") {
        setOutlets(res.data.data);
        if (res.data.data.length > 1) {
          setIsMultiChain(true);
          const chainID = JSON.parse(localStorage.getItem("outletChainID"));
          if (!chainID) {
            setSDisplay(true);
          }
          let x = res.data.data.filter((e) => e.outletChainID === chainID);
          if (x.length < 1) setSDisplay(true);
        } else {
          selectStore(
            res.data.data[0]?.outletChainID,
            res.data.data[0]?.outletName
          );
        }
      }
    });
  };

  const selectStore = (id, name) => {
    let prev_outletName = localStorage.getItem("outletname");
    let prev_outletChainID = localStorage.getItem("outletChainID");
    if (prev_outletName !== JSON.stringify(name)) localStorage.clear();
    if (prev_outletChainID !== JSON.stringify(id)) localStorage.clear();
    localStorage.setItem("outletname", JSON.stringify(name));
    localStorage.setItem("outletChainID", JSON.stringify(id));
    //    window.location.reload();
  };

  return (
    <>
      {/* <img src={fullBanner} alt="fullBanner" style={{width:"100%"}} /> */}
      <div style={{ width: "100%" }}>
        <Banner />
      </div>

      <div className="container" style={{ maxWidth: "1185px" }}>
        {!isAge ? <Starter /> : null}
        <Categories />
      </div>

      <div
        className="container"
        style={{ maxWidth: "1185px", paddingBottom: "102px" }}>
        <ProductCards title="Featured products" />
      </div>

      <div style={{ width: "100%" }}>
        <Infobanner />
      </div>

      <div
        className="container"
        style={{ maxWidth: "1185px", marginBottom: "90px" }}>
        <Popular title="Popular Today" />
      </div>
    </>
  );
}

export default Home;
